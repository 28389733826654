import React, {useEffect, useState} from 'react'

import './styles.css';

const CockiesAlert = () => {
  const [showCockiesAlert, setShowCockiesAlert] = useState(null);

  useEffect(() => {
    try {
      let newShowCockiesAlert = localStorage.getItem('schenckelni@showCockiesAlert');
      setShowCockiesAlert(newShowCockiesAlert)
    } catch (error) {
      console.log(error);
    }
  }, [])

  const handleOnCLickOk = () => {
    try {
      localStorage.setItem('schenckelni@showCockiesAlert', 'false')
      setShowCockiesAlert('false')
    } catch (error) {
      console.log(error);
    }
  }

  console.log('showCockiesAlert', showCockiesAlert);

  if (showCockiesAlert !== 'false') {
    return (
      <div className='CockiesAlertContainer'>
        <div className='CockiesAlertWrapper'>
          <p className='CockiesAlertMessage'>Usamos cookies para personalizar sua navegação e melhorar a sua experiência no site.</p>
          <button className='CockiesAlertButton' onClick={handleOnCLickOk}>Ok, entendi!</button>
        </div>
      </div>
    )
  }

  return (<></>)
}

export default CockiesAlert;