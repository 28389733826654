///////
// RJS
//////
import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";

//////////////
// Application
//////////////

// import NumberFormat from 'react-number-format';

/**
 * Config.
 */
import config from "../../config";

/**
 * Helpers.
 */
import * as Helper from "../../helpers";
import * as HOH from "../../helpers/highOrderHelper";

/**
 * Services.
 */
import {
  serviceGetPropertiesFiltered,
  serviceFindPropertyDetails,
} from "../../services/api/properties";
import { serviceGetStates } from "../../services/api/states";
import { serviceGetCities } from "../../services/api/cities";
import { serviceGetNeighborhoods } from "../../services/api/neighborhoods";
import { serviceGetFilter } from "../../services/api/filter";

/**
 * Components.
 */
import PrettySelect from "../../components/select/pretty";
import ClassicSelect from "../../components/select/classic";
// import InputRange from "../../components/input/range";
// import Input from "../../components/input";

/**
 * Styles.
 */
import "./styles.css";

/**
 * dataSearch INITIAL state.
 */
const dataSearchInitial = {
  options: {
    states: [],
    cities: [],
    neighborhoods: [],
    types: [],
    bedrooms: [],
    garages: [],
    orderBy: [],
    faixaPreco: []
  },
  values_selected: {
    states: [],
    cities: [],
    neighborhoods: [],
    types: [],
    bedrooms: [],
    garages: [],
    priceMin: "",
    priceMax: "",
    orderBy: "",
    faixaPreco: null
  },
};

/**
 * Find Property - Timer
 */
let timer = null;

const Filter = ({
  dataSearch,
  handleSetDataSearch = () => {
    console.log("Filter handleSetDataSearch VALUE DEFAULT.");
  },
  handleDataSearchResult = () => {
    console.log("Filter handleDataSearchResult VALUE DEFAULT.");
  },
  urlFilterPropertiesExternal = null,
}) => {
  /**
   * Handle select tab filter.
   */
  const [filterOption, setFilterOption] = useState(
    // HOH.isUrl(config.navigation.properties.link) ? "advanced" : "codeName"
    'advanced'
  );

  const handleChangeTabFilter = (tab) => setFilterOption(tab);

  /**
   * 1 - Get filter query string URL AND get request properties result API.
   */
  // eslint-disable-next-line
  const getFilterProperties = async () => {
    try {
      const result = await serviceGetPropertiesFiltered({});

      Helper.consoleBeggin("DEBUGFILTRO");
      console.log("useEffect() getFilterProperties(), result:", result);
      Helper.consoleEnd("DEBUGFILTRO");

      const newTypes = result.data.search.options.types.filter((itemType) => itemType.tipo.toString() !== 'Chalé' && itemType.tipo.toString() !== 'Chácara' && itemType.tipo.toString() !== 'Casa Alvenaria' && itemType.tipo.toString() !== 'Casa Geminada' && itemType.tipo.toString() !== 'Casa Mista')
      newTypes.push({ tipo: 'Casa' });
      result.data.search.options.types = newTypes.sort(function(a,b){
        return a.tipo.localeCompare(b.tipo);
      });

      console.log("useEffect() getFilterProperties() @getFilterProperties newTypes:", newTypes);

      result.data.search.options.cities = Object.entries(
        result.data.search.options.cities
      );
      result.data.search.options.neighborhoods = Object.entries(
        result.data.search.options.neighborhoods
      );

      result.data.search.options.faixaPreco = [
        { id: '0-300000', name: 'até R$300.000,00' },
        { id: '300000-1000000', name: 'dê R$300.000,00 até R$1.000.000,00' },
        { id: '1000000-1900000', name: 'dê R$1.000.000,00 até R$1.900.000,00' },
        { id: '1900000-2500000', name: 'dê R$1.900.000,00 até R$2.500.000,00' },
        { id: '2500000-1000000000', name: 'acima de R$2.500.000,00' },
      ]

      const selectedPriceMin = result.data.search.options.faixaPreco.find(item => item.id.split('-')[0] === result.data.search.values_selected.priceMin);
      console.log("useEffect() getFilterProperties(), selectedPriceMin:", selectedPriceMin);
      result.data.search.values_selected.faixaPreco = selectedPriceMin?.id || null;

      handleSetDataSearch(result?.data?.search);
      handleDataSearchResult(result?.data?.paginate);
    } catch (error) {
      console.log(error);
    }
  };

  // eslint-disable-next-line
  const getStates = async () => {
    const result = await serviceGetStates();

    if (!dataSearch?.options && !dataSearch?.values_selected) {
      dataSearchInitial.options.states = result.data;
      dataSearchInitial.values_selected.states = [];

      handleSetDataSearch(Helper.deepClone(dataSearchInitial));
    } else {
      dataSearch.values_selected.states = result.data;
      dataSearch.values_selected.states = [];

      handleSetDataSearch(dataSearch);
    }

    /**
     * Get new cities.
     */
    getCities();
  };

  // eslint-disable-next-line
  const getFilter = async () => {
    try {
      Helper.consoleBeggin("DEBUGFILTRO");
      const result = await serviceGetFilter();
      console.log("FilterComponent@getFilter result:", result);

      // if (!dataSearch?.options && !dataSearch?.values_selected) {
      console.log(
        "FilterComponent@getFilter IF !dataSearch?.options && !dataSearch?.values_selected"
      );

      const newTypes = result.data.options.types.filter((itemType) => itemType.tipo.toString() !== 'Chalé' && itemType.tipo.toString() !== 'Chácara' &&  itemType.tipo.toString() !== 'Casa Alvenaria' && itemType.tipo.toString() !== 'Casa Geminada' && itemType.tipo.toString() !== 'Casa Mista')
      newTypes.push({ tipo: 'Casa' });
      dataSearchInitial.options.types = newTypes.sort(function(a,b){
        return a.tipo.localeCompare(b.tipo);
      });
      
      dataSearchInitial.options.bedrooms = result.data.options.bedrooms;
      dataSearchInitial.options.garages = result.data.options.garages;

      dataSearchInitial.options.faixaPreco = [
        { id: '0-300000', name: 'até R$300.000,00' },
        { id: '300000-1000000', name: 'dê R$300.000,00 até R$1.000.000,00' },
        { id: '1000000-1900000', name: 'dê R$1.000.000,00 até R$1.900.000,00' },
        { id: '1900000-2500000', name: 'dê R$1.900.000,00 até R$2.500.000,00' },
        { id: '2500000-1000000000', name: 'acima de R$2.500.000,00' },
      ]

      console.log("FilterComponent@getFilter dataSearchInitial.options:", dataSearchInitial.options);

      handleSetDataSearch(Helper.deepClone(dataSearchInitial));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    /**
     * 1.1 - Get properties initial queryString filters.
     */
    /* If user is on page /IMOVEIS do get to /properties filter with values queryStrings */
    if (HOH.isUrl(config.navigation.properties.link)) {
      Helper.consoleBeggin("DEBUGFILTRO");
      console.log("useEffect() IS /IMOVEIS PAGE");
      Helper.consoleEnd("DEBUGFILTRO");

      getFilterProperties();
    } else {
      Helper.consoleBeggin("DEBUGFILTRO");
      console.log("useEffect() NOT IS /IMOVEIS PAGE");
      Helper.consoleEnd("DEBUGFILTRO");

      /* Else only do get request /states API */

      getStates();
      getFilter();
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Handle Get filter properties.
   */
  const [urlFilterProperties, setUrlFilterProperties] = useState(null);

  useEffect(() => {
    if (urlFilterProperties || urlFilterPropertiesExternal) {
      /**
       * 2.4 - Get properties of API with news queryString filters.
       */
      console.log(
        "getFilterProperties() TARGET urlFilterProperties:",
        urlFilterProperties
      );
      getFilterProperties();
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line
  }, [urlFilterProperties, urlFilterPropertiesExternal]);

  const handleGetFilterProperties = () => {
    try {
      Helper.consoleBeggin();
      console.log("handleGetFilterProperties dataSearch:", dataSearch);

      // if (dataSearch.values_selected.types.includes('Casa')) {
      //   dataSearch.values_selected.types.push('Casa Alvenaria');
      //   dataSearch.values_selected.types.push('Casa Geminada');
      //   dataSearch.values_selected.types.push('Casa Mista');
      // }

      // console.log("handleGetFilterProperties dataSearch:", dataSearch);

      const queryString = Helper.setParamsUrlFilter(
        dataSearch?.values_selected
      );

      console.log("queryString:", queryString);

      const newUrlFilterProperties = `${config.navigation.properties.link}?${queryString}`;

      /**
       * 2.1 - Set URL propertie with filter queryStrings ...
       */
      setUrlFilterProperties(newUrlFilterProperties);
      Helper.consoleEnd();
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Handle cities.
   */
  const getCities = async () => {
    try {
      const states = dataSearch.values_selected.states;
      const queryString = Helper.setParamsUrlFilter({ states });

      const result = await serviceGetCities({ queryString });

      dataSearch.options.cities = Object.entries(result.data);
      const newDataValuesSelectedCities = [];

      if (dataSearch.options.cities.length) {
        dataSearch.options.cities.map((stateGroup) => {
          stateGroup[1].map((c) => {
            if (dataSearch.values_selected.cities.includes(c.id.toString())) {
              newDataValuesSelectedCities.push(c.id.toString());
            }
          });
        });
      }

      console.log(
        "DEBUGFILTER newDataValuesSelectedCities:",
        newDataValuesSelectedCities
      );
      dataSearch.values_selected.cities = newDataValuesSelectedCities;

      handleSetDataSearch(Helper.deepClone(dataSearch));

      /**
       * Get new neighborhoods.
       */
      getNeighborhoods();
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Handle neighborhoods.
   */
  const getNeighborhoods = async () => {
    try {
      const cities = dataSearch.values_selected.cities;
      const queryString = Helper.setParamsUrlFilter({ cities });

      const result = await serviceGetNeighborhoods({ queryString });

      dataSearch.options.neighborhoods = Object.entries(result.data);
      const newDataValuesSelectedNeighborhoods = [];

      if (dataSearch.options.neighborhoods.length) {
        dataSearch.options.neighborhoods.map((neighborhoodGroup) => {
          neighborhoodGroup[1].map((b) => {
            if (
              dataSearch.values_selected.neighborhoods.includes(b.id.toString())
            ) {
              newDataValuesSelectedNeighborhoods.push(b.id.toString());
            }
          });
        });
      }

      console.log(
        "DEBUGFILTER newDataValuesSelectedNeighborhoods:",
        newDataValuesSelectedNeighborhoods
      );
      dataSearch.values_selected.neighborhoods = newDataValuesSelectedNeighborhoods;

      handleSetDataSearch(Helper.deepClone(dataSearch));
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Handle finde property per code or name/title property.
   */
  const [findProperty, setFindProperty] = useState({});

  const findPropertyDetails = async ({ code }) => {
    try {
      const response = await serviceFindPropertyDetails({ code });

      Helper.consoleBeggin();
      console.log("findPropertyDetails response:", response);
      Helper.consoleEnd();

      const loading = false;
      const result = response?.data?.property;
      setFindProperty({ loading, result });
    } catch (e) {
      console.log(e);
    }
  };

  const handleFindPropertyCodeOrName = (e) => {
    try {
      const loading = true;
      setFindProperty({ ...findProperty, loading });
      const code = e.target.value;

      Helper.consoleBeggin();
      console.log("Filter handleFindPropertyCodeOrName(), code:", code);

      if (Helper.isValidCode(code)) {
        clearTimeout(timer);

        timer = setTimeout(() => {
          console.log("Filter handleFindPropertyCodeOrName() TIMEOUT");
          findPropertyDetails({ code });
        }, 1500);
      } else {
        clearTimeout(timer);

        const loading = false;
        const result = {};
        setFindProperty({ result, loading });
      }

      Helper.consoleEnd();
    } catch (e) {
      console.log(e);

      const loading = false;
      const result = {};
      setFindProperty({ result, loading });
    }
  };

  console.log("Filter findProperty:", findProperty);

  return (
    <div className="Filter">
      {/**
       * 2.3 - Does Redirect with new URLs filter...
       */}
      {urlFilterProperties && <Redirect to={urlFilterProperties} />}

      <div className="FilterContainer">
        <div className="FilterTabsContainer">
          <button
            onClick={() => handleChangeTabFilter("advanced")}
            className={`FilterTabsButton ${
              filterOption === "advanced" ? "active" : ""
            }`}
          >
            Busca
          </button>
          <button
            onClick={() => handleChangeTabFilter("codeName")}
            className={`FilterTabsButton ${
              filterOption === "codeName" ? "active" : ""
            }`}
            data-testid="testButtonhandleChangeTabFilterShowCodeName"
          >
            Código
          </button>
        </div>
        <div className="FilterContentContainer">
          {/**
           *  ADVANCED FILTER.
           */}
          {filterOption === "advanced" && (
            <div className="FilterContent advancedContainer">
              <PrettySelect
                title="Tipo(opcional)"
                placeholder="Selecione"
                data={dataSearch?.options?.types}
                dataSelected={dataSearch?.values_selected?.types}
                keyDesc="tipo"
                keyId="tipo"
                handleSelectOption={(item) => {
                  console.log('DEBUGTIPOS item:', item);
                  dataSearch.values_selected.types.push(item.tipo.toString());
                  if (item.tipo === 'Casa') {
                    // Casa Alvenaria, Casa Geminada, Casa Mista
                    dataSearch.values_selected.types.push('Casa Alvenaria');
                    dataSearch.values_selected.types.push('Casa Geminada');
                    dataSearch.values_selected.types.push('Casa Mista');
                  }
                  handleSetDataSearch(Helper.deepClone(dataSearch));
                }}
                handleUnselectOption={(item) => {
                  let newData = null;

                  if (item.tipo === 'Casa') {
                    newData = dataSearch.values_selected.types.filter(
                      (state) => state.toString() !== 'Casa' &&state.toString() !== 'Casa Alvenaria' && state.toString() !== 'Casa Geminada' && state.toString() !== 'Casa Mista'
                    );
                  } else {
                    newData = dataSearch.values_selected.types.filter(
                      (state) => state.toString() !== item.tipo.toString()
                    );
                  }
                  dataSearch.values_selected.types = newData;
                  handleSetDataSearch(Helper.deepClone(dataSearch));
                }}
              />

              <div className="FilterDorGarContainer">
                {/* <div className="FilterInputContainer"> */}
                  <PrettySelect
                    title="Dormitório(opcional)"
                    placeholder="Selecione"
                    data={dataSearch?.options?.bedrooms}
                    dataSelected={dataSearch?.values_selected?.bedrooms}
                    keyDesc="dormitorio"
                    keyId="dormitorio"
                    handleSelectOption={(item) => {
                      dataSearch.values_selected.bedrooms.push(
                        item.dormitorio.toString()
                      );
                      handleSetDataSearch(Helper.deepClone(dataSearch));
                    }}
                    handleUnselectOption={(item) => {
                      const newData = dataSearch.values_selected.bedrooms.filter(
                        (state) => state.toString() !== item.dormitorio.toString()
                      );
                      dataSearch.values_selected.bedrooms = newData;
                      handleSetDataSearch(Helper.deepClone(dataSearch));
                    }}
                  />
                {/* </div>
                <div className="FilterInputContainer"> */}
                  <PrettySelect
                    title="Garagem(opcional)"
                    placeholder="Selecione"
                    data={dataSearch?.options?.garages}
                    dataSelected={dataSearch?.values_selected?.garages}
                    keyDesc="garagem"
                    keyId="garagem"
                    handleSelectOption={(item) => {
                      dataSearch.values_selected.garages.push(
                        item.garagem.toString()
                      );
                      handleSetDataSearch(Helper.deepClone(dataSearch));
                    }}
                    handleUnselectOption={(item) => {
                      const newData = dataSearch.values_selected.garages.filter(
                        (state) => state.toString() !== item.garagem.toString()
                      );
                      dataSearch.values_selected.garages = newData;
                      handleSetDataSearch(Helper.deepClone(dataSearch));
                    }}
                  />
                {/* </div> */}
              </div>

              <PrettySelect
                title="Estado(opcional)"
                placeholder="Selecione"
                data={dataSearch?.options?.states}
                dataSelected={dataSearch?.values_selected?.states}
                keyDesc="abbr"
                keyId="id"
                handleSelectOption={(item) => {
                  dataSearch.values_selected.states.push(item.id.toString());
                  handleSetDataSearch(Helper.deepClone(dataSearch));
                  getCities();
                }}
                handleUnselectOption={(item) => {
                  const newData = dataSearch.values_selected.states.filter(
                    (state) => state.toString() !== item.id.toString()
                  );
                  dataSearch.values_selected.states = newData;
                  handleSetDataSearch(Helper.deepClone(dataSearch));
                  getCities();
                }}
              />

              <PrettySelect
                title="Cidades(opcional)"
                placeholder="Selecione"
                data={dataSearch?.options?.cities}
                dataSelected={dataSearch?.values_selected?.cities}
                keyDesc="name"
                keyId="id"
                handleSelectOption={(item) => {
                  dataSearch.values_selected.cities.push(item.id.toString());
                  handleSetDataSearch(Helper.deepClone(dataSearch));
                  getNeighborhoods();
                }}
                handleUnselectOption={(item) => {
                  const newData = dataSearch.values_selected.cities.filter(
                    (city) => city.toString() !== item.id.toString()
                  );
                  dataSearch.values_selected.cities = newData;
                  handleSetDataSearch(Helper.deepClone(dataSearch));
                  getNeighborhoods();
                }}
                hasGroup
              />

              <PrettySelect
                title="Bairro(opcional)"
                placeholder="Selecione"
                data={dataSearch?.options?.neighborhoods}
                dataSelected={dataSearch?.values_selected?.neighborhoods}
                keyDesc="name"
                keyId="id"
                handleSelectOption={(item) => {
                  dataSearch.values_selected.neighborhoods.push(
                    item.id.toString()
                  );
                  handleSetDataSearch(Helper.deepClone(dataSearch));
                }}
                handleUnselectOption={(item) => {
                  const newData = dataSearch.values_selected.neighborhoods.filter(
                    (neighborhood) =>
                      neighborhood.toString() !== item.id.toString()
                  );
                  dataSearch.values_selected.neighborhoods = newData;
                  handleSetDataSearch(Helper.deepClone(dataSearch));
                }}
                hasGroup
              />

              {/* <InputRange
                title="Dê"
                value={dataSearch?.values_selected?.priceMin || 0}
                min={0}
                max={5000000}
                step={5000}
                handleChangeRange={(e) => {
                  Helper.consoleBeggin();
                  console.log("handleChangeRange Dê, value:", e.target.value);
                  dataSearch.values_selected.priceMin = e.target.value;
                  handleSetDataSearch(Helper.deepClone(dataSearch));
                  Helper.consoleEnd();
                }}
              />
              <InputRange
                title="Até"
                value={dataSearch?.values_selected?.priceMax || 5000000}
                min={dataSearch?.values_selected?.priceMin}
                max={5000000}
                step={5000}
                handleChangeRange={(e) => {
                  Helper.consoleBeggin();
                  console.log("handleChangeRange Até, value:", e.target.value);
                  dataSearch.values_selected.priceMax = e.target.value;
                  handleSetDataSearch(Helper.deepClone(dataSearch));
                  Helper.consoleEnd();
                }}
              /> 
              <div className="FilterValoresContainer">
                <div className="FilterInputContainer">
                  <div className="FilterInputHeader">
                    <h5 className="FilterInputHeaderTitle">Dê(opcional)</h5>
                  </div>
                  <NumberFormat
                    className="FilterInput"
                    type="text"
                    name="de"
                    placeholder='Somente números...'
                    thousandSeparator='.'
                    decimalSeparator={false}
                    value={dataSearch?.values_selected?.priceMin}
                    onChange={(e) => {
                      Helper.consoleBeggin();
                      // console.log("handleChangeRange Dê, value:", newValue);
                      dataSearch.values_selected.priceMin = e.target.value;
                      handleSetDataSearch(Helper.deepClone(dataSearch));
                      Helper.consoleEnd();
                    }}
                  />
                </div>
                <div className="FilterInputContainer">
                  <div className="FilterInputHeader">
                    <h5 className="FilterInputHeaderTitle">Até(opcional)</h5>
                  </div>
                  <NumberFormat
                    className="FilterInput"
                    type="text"
                    name="de"
                    placeholder='Somente números...'
                    thousandSeparator='.'
                    decimalSeparator={false}
                    value={dataSearch?.values_selected?.priceMax}
                    onChange={(e) => {
                      Helper.consoleBeggin();
                      // console.log("handleChangeRange Dê, value:", e.target.value);
                      dataSearch.values_selected.priceMax = e.target.value;
                      handleSetDataSearch(Helper.deepClone(dataSearch));
                      Helper.consoleEnd();
                    }}
                  />
                </div>
              </div>
              */}
              <ClassicSelect
                title="Faixa de preço"
                placeholder="Selecione"
                data={dataSearch?.options?.faixaPreco}
                dataSelected={dataSearch?.values_selected?.faixaPreco}
                // keyDesc="faixa-de-preco"
                // keyId="faixa-de-preco"
                handleSelectOption={(item) => {
                  // dataSearch.values_selected.garages.push(
                  //   item.garagem.toString()
                  // );
                  console.log('DEBUGFAIXA item:', item);
                  // handleSetDataSearch(Helper.deepClone(dataSearch));
                  dataSearch.values_selected.priceMin = item.split('-')[0];
                  dataSearch.values_selected.priceMax = item.split('-')[1];
                  dataSearch.values_selected.faixaPreco = item;
                  handleSetDataSearch(Helper.deepClone(dataSearch));
                }}
                handleUnselectOption={(item) => {
                  // const newData = dataSearch.values_selected.garages.filter(
                  //   (state) => state.toString() !== item.garagem.toString()
                  // );
                  // dataSearch.values_selected.garages = newData;
                  // handleSetDataSearch(Helper.deepClone(dataSearch));
                }}
              />

              <div className="FilterContentFooter">
                {/**
                 * 2 - User resend new GET with news filters properties API.
                 */}
                <button
                  type="button"
                  className="FilterButtonSubmit"
                  onClick={handleGetFilterProperties}
                >
                  Filtrar
                </button>
              </div>
            </div>
          )}

          {/**
           * CODE PROPERTY FILTER.
           */}
          {filterOption === "codeName" && (
            <div className="FilterContent">
              <div className="FilterInputContainer">
                <input
                  className="FilterInput"
                  type="text"
                  placeholder="Ex: 'AP080'"
                  onChange={handleFindPropertyCodeOrName}
                  data-testid="testInputFindPropertyByIdOrName"
                />
              </div>
              <div className="FilterResultFindContainer">
                <div className="FilterResultFindContent">
                  {findProperty?.result?.code && (
                    <div className="FilterResult">
                      <div className="FilterResultImageContainer">
                        <img
                          className="FilterResultImage"
                          src={HOH.getPhotoProperty(
                            findProperty?.result,
                            "thumb"
                          )}
                          alt="Imóvel encontrado na busca"
                        />
                      </div>
                      <div className="FilterResultInfosContainer">
                        <h4 className="FilterResultInfosTitle">
                          {HOH.getTitleInfoProperty(findProperty?.result)}
                          <br />
                          <small>
                            {HOH.getCodeInfoProperty(findProperty?.result)}
                          </small>
                        </h4>
                        <p className="FilterResultInfosDesc">
                          {findProperty?.result?.descGeral}
                        </p>
                        <p className="FilterResultInfosLinkContainer">
                          <Link
                            className="FilterResultInfosLink"
                            to={HOH.getUrlProperty(findProperty?.result)}
                          >
                            Conhecer +
                          </Link>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* <a href="/imoveis?states%5B%5D=43&cities%5B%5D=4300877&cities%5B%5D=4303905&neighborhoods%5B%5D=75&neighborhoods%5B%5D=4&tipos%5B%5D=Apartamento&tipos%5B%5D=Casa+Alvenaria&dormitorios%5B%5D=1&dormitorios%5B%5D=2&garagens%5B%5D=3&garagens%5B%5D=4&valorDe=1155500&valorAte=2140000">
            FILTRAR: Test decode queryStrings and encode again.
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Filter;
