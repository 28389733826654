///////
// RJS
///////
import React, { useEffect, useState, useCallback } from "react";

/**
 * React router dom.
 */

/**
 * Link: Link prevent refresh page implemented a real redirect in applications SIGLE PAGE APPLICATION.
 */
import { Link } from "react-router-dom";

/**
 * Libs.
 */
import {
  FaWhatsapp,
  // FaRegEnvelope,
  FaInstagram,
  FaFacebook,
  // FaPhoneAlt,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";

// import { FiPhoneCall } from "react-icons/fi";

//////////////
// Application
//////////////

/**
 * Assets.
 */
import logoSecondary from "../../assets/schenckelni-logo.png";

/**
 * Helpers.
 */
import * as HOH from "../../helpers/highOrderHelper";
import * as Helper from "../../helpers";

/**
 * Config.
 */
import config from "../../config";

/**
 * Styles.
 */
import "./styles.css";

/**
 * Services.
 */
import { serviceGetBanners } from "../../services/api/banners";

/**
 * Interval data.
 */
const intervalBannerSlide = {
  index: 0,
  id: null,
};

const Header = ({ slide }) => {
  useEffect(() => {
    if (slide) getBanners();
  }, [slide]);

  /**
   * Get banners.
   */
  const [dataBanners, setDataBanners] = useState([]);
  const [dataBannersActive, setDataBannersActive] = useState({});

  const getBanners = async () => {
    const result = await serviceGetBanners();
    if (result?.data?.length) setDataBannersActive(result.data[0]);
    setDataBanners(result?.data);
  };

  const handlePrevSlide = () => {
    try {
      if (intervalBannerSlide.id) clearInterval(intervalBannerSlide.id);
      let dataBannersIndex = intervalBannerSlide.index - 1;
      if (intervalBannerSlide.index === 0) dataBannersIndex = dataBanners.length - 1;
      intervalBannerSlide.index = dataBannersIndex;
      setDataBannersActive(dataBanners[dataBannersIndex]);
    } catch (error) {
      console.log(error);
    }
  }
  const handleNextSlide = () => {
    try {
      if (intervalBannerSlide.id) clearInterval(intervalBannerSlide.id);
      let dataBannersIndex = intervalBannerSlide.index + 1;
      if (intervalBannerSlide.index === dataBanners.length-1) dataBannersIndex = 0;
      intervalBannerSlide.index = dataBannersIndex;
      setDataBannersActive(dataBanners[dataBannersIndex]);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Mobile Main.
   */
  const [openMainMobile, setOpenMainMobile] = useState(false);

  const handleOpenMainMobile = () => setOpenMainMobile(true);
  const handleCloseMainMobile = () => setOpenMainMobile(false);

  const initBannerSlideInteval = useCallback(() => {
    if (dataBanners?.length) {
      intervalBannerSlide.id = setInterval(() => {
        intervalBannerSlide.index++;

        if (intervalBannerSlide.index >= dataBanners?.length) {
          intervalBannerSlide.index = 0; 
          clearInterval(intervalBannerSlide.id);
          initBannerSlideInteval();
        }

        setDataBannersActive(dataBanners[intervalBannerSlide.index]);
      }, 5000);
    }
  });

  /**
   * Set interval slide banner.
   */
  useEffect(() => {
    Helper.consoleBeggin("BANNER");
    initBannerSlideInteval();
    Helper.consoleEnd("BANNER");
    // eslint-disable-next-line
  }, [dataBanners, slide]);

  /**
   * Handle change banner data.
   */
  const handleDataBannerActive = (banner) => {
    if (intervalBannerSlide.id) clearInterval(intervalBannerSlide.id);
    setDataBannersActive(banner);
  };

  return (
    <>
      <div className="Header">
        <div className="HeaderCol HeaderColLeft">
          <div className="HeaderContainerMain">
            {/* <a
              className="HeaderMainItem"
              href={config.social.email.link}
              target="_blank"
              title={config.social.email.title}
            >
              <FaRegEnvelope className="HeaderIcon" />
              <span className="HeaderIconDesc">{config.social.email.desc}</span>
            </a>
            <a
              className="HeaderMainItem HeaderMainItemWhats"
              href={`${HOH.openLinkWebOrDeeplinkDevice(
                config.social.whats
              )}&text=Olá, gostaria de saber...`}
              target="_blank"
              title={config.social.whats.title}
            >
              <FaWhatsapp className="HeaderIcon" />
              <span className="HeaderIconDesc">{config.social.whats.desc}</span>
            </a> */}
            <div className="SubHeaderConainerBrand">
              <Link to={config.navigation.home.link} title="Ir para Home">
                <img
                  className="SubHeaderBrand"
                  src={logoSecondary}
                  alt="Schenckel Logo"
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="HeaderCol HeaderColCenter">
          <div className="SecondaryHeaderContent">
            {config.social.phones.map((phone, i) => (
              <a
                key={String(i)}
                className="SecondaryHeaderMainItem"
                href={phone.link}
                target="_blank"
                title={phone.title}
              >
                {/* <FiPhoneCall className="HeaderIcon" /> */}
                {phone.desc.indexOf('3761') > -1 && (<FaWhatsapp className="HeaderIcon HeaderIconWhats" />)}
                <span className="HeaderIconDesc">{phone.desc}</span>
              </a>
            ))}
          </div>
          <div className="HeaderContainerIcons">
            <a
              className="HeaderMainItem"
              href={config.social.insta.link}
              target="_blank"
              title={config.social.insta.title}
            >
              <FaInstagram className="HeaderIcon" />
              <span className="HeaderIconDesc">{config.social.insta.desc}</span>
            </a>
            <a
              className="HeaderMainItem"
              href={config.social.face.link}
              target="_blank"
              title={config.social.face.alt}
            >
              <FaFacebook
                className="HeaderIcon"
                // className="HeaderIcon"
              />
              <span className="HeaderIconDesc">{config.social.face.desc}</span>
            </a>
          </div>
        </div>
        <div className="HeaderCol HeaderColRight">
          <div className="HeaderContainerIcons">
            <a
              className="HeaderMainItem"
              href={config.social.insta.link}
              target="_blank"
              title={config.social.insta.title}
            >
              <FaInstagram className="HeaderIcon" />
              <span className="HeaderIconDesc">{config.social.insta.desc}</span>
            </a>
            <a
              className="HeaderMainItem"
              href={config.social.face.link}
              target="_blank"
              title={config.social.face.alt}
            >
              <FaFacebook
                className="HeaderIcon"
                // className="HeaderIcon"
              />
              <span className="HeaderIconDesc">{config.social.face.desc}</span>
            </a>
          </div>
        </div>
      </div>
      {/* <div className="SecondaryHeader"> */}
        {/* <div className="SecondaryHeaderContent">
          {config.social.phones.map((phone, i) => (
            <a
              key={String(i)}
              className="SecondaryHeaderMainItem"
              href={phone.link}
              target="_blank"
              title={phone.title}
            >
              <FiPhoneCall className="HeaderIcon" />
              {phone.desc.indexOf('3761') > -1 && (<FaWhatsapp className="HeaderIcon HeaderIconWhats" />)}
              <span className="HeaderIconDesc">{phone.desc}</span>
            </a>
          ))}
        </div> */}
      {/* </div> */}

      <div
        className="PriorityOneContainer"
        style={{
          backgroundImage: slide ? `url(${dataBannersActive?.normal})` : "none",
        }}
      >
        {!!slide && <div className="BannerBlur BannerBlurLeft"></div>}
        {!!slide && <div className="BannerBlur BannerBlurRight"></div>}
        {/* <div className="SubHeader">
          <div className="SubHeaderConainerBrand">
            <Link to={config.navigation.home.link} title="Ir para Home">
              <img
                className="SubHeaderBrand"
                src={logoSecondary}
                alt="Schenckel Logo"
              />
            </Link>
          </div> 
          <div className="SubHeaderConainerMain">
            <ul className="SubHeaderMain">
              <li className="SubHeaderMainItem active">
                <Link
                  to={config.navigation.home.link}
                  title={HOH.getNavigationTitle(config.navigation.home.desc)}
                >
                  {config.navigation.home.desc}
                </Link>
              </li>
              <li className="SubHeaderMainItem">
                <Link
                  to={config.navigation.contact}
                  title="Ir para página Fale Conosco"
                >
                  Fale Conosco
                </Link>
              </li> 
            </ul>
          </div>
          <div className="SubHeaderConainerMainMobile">
            <ul className="SubHeaderMainMobile" onClick={handleOpenMainMobile}>
              <span className="SubHeaderMainMobileLine"></span>
              <span className="SubHeaderMainMobileLine"></span>
              <span className="SubHeaderMainMobileLine"></span>
            </ul>
          </div>
        </div> */}
        {slide && (
          <div className="SlideContainer">
            <div className="Slide">
              <div className="BannerContainer">
                <div className="Banner">
                  <div className="BannerInfosContainer">
                    {/**
                     * Mobile.
                     */}
                    {dataBannersActive?.titulo && (
                      <h1 className="BannerInfoTitle">
                        {Helper.cropString(dataBannersActive?.titulo, 50)}
                      </h1>
                    )}
                    {dataBannersActive?.descGeral && (
                      <div
                        className={`BannerInfoDesc ${
                          !dataBannersActive?.titulo ? "noTitle" : ""
                        }`}
                      >
                        {Helper.cropString(dataBannersActive?.descGeral, 230)}
                      </div>
                    )}

                    {/**
                     * Desktop.
                     */}
                    {dataBannersActive?.titulo && (
                      <h1 className="BannerInfoTitle desk">
                        {Helper.cropString(dataBannersActive?.titulo, 50)}
                      </h1>
                    )}
                    {dataBannersActive?.descGeral && (
                      <div
                        className={`BannerInfoDesc desk ${
                          !dataBannersActive?.titulo ? "noTitle" : ""
                        }`}
                      >
                        {Helper.cropString(dataBannersActive?.descGeral, 230)}
                      </div>
                    )}

                    <div
                      className={`BannerInfoLink ${
                        !dataBannersActive?.titulo &&
                        !dataBannersActive?.descGeral
                          ? "noTitleNoDesc"
                          : ""
                      }`}
                    >
                      <Link
                        to={HOH.getUrlProperty(
                          dataBannersActive?.property?.data
                        )}
                        title={HOH.getNavigationTitle(
                          config.navigation.properties.desc
                        )}
                      >
                        Gostei, ver mais!
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`SlideThumbsContainer 
              ${dataBanners?.length >= 3 ? " SlideThumbsContainer3 " : ""} 
              ${dataBanners?.length >= 6 ? " SlideThumbsContainer6 " : ""}
              ${dataBanners?.length >= 9 ? " SlideThumbsContainer9 " : ""}
              ${dataBanners?.length >= 12 ? " SlideThumbsContainer12 " : ""}`}
            >
              {!!dataBanners?.length &&
                dataBanners.map((banner, i) => (
                  <div
                    key={String(i)}
                    className={`SlideThumb ${
                      banner?.id === dataBannersActive?.id ? "active" : ""
                    }`}
                    onClick={() => handleDataBannerActive(banner)}
                  >
                    <img
                      className="ThumbImage"
                      src={banner?.thumb}
                      alt={banner?.titulo}
                    />
                  </div>
                ))}
            </div>
            <FaAngleLeft className="SlideContainer--btn-prev" onClick={handlePrevSlide} />
            <FaAngleRight className="SlideContainer--btn-next" onClick={handleNextSlide} />
          </div>
        )}
      </div>

      {/**
       * Fixed Elements.
       */}
      {openMainMobile && (
        <div className="SubHeaderMainMobileModal">
          <ul
            className="SubHeaderMainMobileClose"
            onClick={handleCloseMainMobile}
          >
            <span className="SubHeaderMainMobileLine"></span>
            <span className="SubHeaderMainMobileLine"></span>
          </ul>
          <ul className="SubHeaderMain">
            <li className="SubHeaderMainItem active">
              <Link
                to={config.navigation.home.link}
                title={HOH.getNavigationTitle(config.navigation.home.desc)}
              >
                {config.navigation.home.desc}
              </Link>
            </li>
            {/* <li className="SubHeaderMainItem">Fale Conosco</li> */}
          </ul>
        </div>
      )}
    </>
  );
};

export default Header;
