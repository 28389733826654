/**
 * Assets.
 */
 import logoBB from "../assets/banks/logo-bb.png";
 import logoCaixa from "../assets/banks/logo-caixa.png";
 import logoInter from "../assets/banks/logo-inter.png";
 import logoItau from "../assets/banks/logo-itau.png";
 import logoBanrisul from "../assets/banks/logo-banrisul.png";
 import logoBradesco from "../assets/banks/logo-bradesco.png";
 import logoSantander from "../assets/banks/logo-santander.png";

export const dataBanks = [
  {
    link: 'https://www.santander.com.br/atendimento-para-voce/simuladores/simulador-credito-imobiliario',
    image: logoSantander,
    name: 'Santander'
  },
  {
    link: 'https://www.bb.com.br/pbb/pagina-inicial/voce/produtos-e-servicos/financiamentos/bb-credito-imobiliario#/',
    image: logoBB,
    name: 'Banco do Brasil'
  },
  {
    link: 'http://www8.caixa.gov.br/siopiinternet-web/simulaOperacaoInternet.do?method=inicializarCasoUso',
    image: logoCaixa,
    name: 'Caixa'
  },
  // {
  //   link: 'https://www.bancointer.com.br/pra-voce/financiamento-imobiliario/residencial/?utm_source=google&utm_medium=cpc&utm_campaign=Pesquisa%20-%20Financiamento%20-%20NonBrand&gclid=Cj0KCQjwktKFBhCkARIsAJeDT0j1NQdi6AQFcf_3l8ZWxH5LO3jRTooU90fpEBbxWx31A3Cuw2thg4gaAnjVEALw_wcB',
  //   image: logoInter,
  //   name: 'Inter'
  // },
  {
    link: 'https://www.itau.com.br/emprestimos-financiamentos/credito-imobiliario/simulador',
    image: logoItau,
    name: 'Itaú'
  },
  {
    link: 'https://www.banrisul.com.br/brz/link/brzw01xn_Simulador.aspx?css=bob&secao_id=1083',
    image: logoBanrisul,
    name: 'Banrisul'
  },
  {
    link: 'https://banco.bradesco/html/classic/produtos-servicos/emprestimo-e-financiamento/encontre-seu-credito/simuladores-imoveis.shtm?gclid=CjwKCAjwtdeFBhBAEiwAKOIy58kCWct2KC7Dzm6aw_HqvCiRd7wQnmQhb1VVOfkjztGGp8WNgb9d0hoCMCEQAvD_BwE&gclsrc=aw.ds',
    image: logoBradesco,
    name: 'Bradesco'
  }
]