//////////////
// Application
//////////////

/**
 * Config.
 */
import config from "../../config";

/**
 * Services Http.
 */
import { GET } from "../http";

/**
 * Helpers.
 */
import {
  consoleBeggin,
  consoleEnd,
  getParamsUrlFilter,
  setParamsUrlFilter,
} from "../../helpers";

/**
 * Get news propeties.
 */
export const newsProperties = async () => {
  let data = {};

  try {
    const url = `${config.baseUrlApi}/pub/${config.imob.domain}/properties/news`;
    data = await GET({ url });
    return data;
  } catch (e) {
    console.log(e);
  }

  return data;
};

/**
 *  Get featured properties.
 */
export const serviceGetFeaturedProperties = async () => {
  let data = {};

  try {
    const url = `${config.baseUrlApi}/pub/${config.imob.domain}/properties/featured`;
    data = await GET({ url });
    return data;
  } catch (e) {
    console.log(e);
  }

  return data;
};

/**
 *  Get properties filtered.
 */
export const serviceGetPropertiesFiltered = async ({ queryString }) => {
  let data = {};

  try {
    consoleBeggin("DEBUGFILTRO");
    console.log("serviceGetFilterProperties() queryString:", queryString);

    const valueFilter = getParamsUrlFilter();
    console.log("serviceGetFilterProperties() valueFilter:", valueFilter);

    const queryStringFIlter = setParamsUrlFilter(valueFilter);
    console.log(
      "serviceGetFilterProperties() queryStringFIlter:",
      queryStringFIlter
    );

    const qs = queryString ? queryString : queryStringFIlter;

    const url = `${config.baseUrlApi}/pub/${config.imob.domain}/properties/filtered?${qs}`;
    const result = await GET({ url });

    console.log("serviceGetFilterProperties() result:", result);
    consoleEnd("DEBUGFILTRO");

    return result;
  } catch (e) {
    console.log(e);
  }

  return data;
};

/**
 *  Get property details.
 */
export const serviceGetPropertyDetails = async ({ code }) => {
  let data = {};

  try {
    const url = `${config.baseUrlApi}/pub/${config.imob.domain}/property-new-show/${code}`;
    const response = await GET({ url });
    return response;
  } catch (e) {
    console.log(e);
  }

  return data;
};

/**
 *  Get property by code/name/title post.
 */
export const serviceFindPropertyDetails = async ({ code }) => {
  let data = {};

  try {
    const url = `${config.baseUrlApi}/pub/${config.imob.domain}/property/search/${code}`;
    const response = await GET({ url });
    return response;
  } catch (e) {
    console.log(e);
  }

  return data;
};
