///////
// RJS
//////
import React, { useEffect, useState, useRef } from "react";

/**
 * Google Racaptcha.
 */
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

/**
 * Icons
 */
import { AiOutlineCloseCircle } from "react-icons/ai";

/**
 * Router DOM.
 */
 import { useHistory } from "react-router-dom";

////////////////
// Application.
////////////////

/**
 * Config.
 */
import config from "../../config";

/**
 * Components.
 */
import Alert from "../../components/alert";

/**
 * Helpers.
 */
import * as Helper from "../../helpers";
import * as HOH from "../../helpers/highOrderHelper";

/**
 * Service.
 */
import { serviceProspects } from "../../services/api/prospects";

/**
 * Style.
 */
import "./styles.css";

const CaptureInfos = ({
  dataProspect,
  property,
  handlCallbackSave = () => {
    console.log("handlCallbackSave props DEFAULT.");
  },
}) => {
  /**
   * Redirect with History Router DOM.
   * Example: history.push('/home');
   */
   const history = useHistory();

  const [openModal, setOpenModal] = useState(false);

  /**
   * Data Visitor.
   */
  useEffect(() => {}, [dataProspect]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  /*
   * Data form set.
   */
  const inputName = useRef(null);
  const inputEmail = useRef(null);
  const inputPhone = useRef(null);

  const [dataForm, setDataForm] = useState({});
  const [dataFormResult, setDataFormResult] = useState({});

  const handleSetDataForm = (e) => {
    dataForm[e.target.name] = e.target.value;
    setDataForm(dataForm);
  };

  /**
   * Google Racaptcha.
   */
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [recaptchaAlert, setRecaptchaAlert] = useState(null);

  const handlePostProspect = (e) => {
    try {
      e.preventDefault();

      Helper.consoleBeggin();

      if (recaptchaToken) {
        localStorage.setItem(
          `@${config.imob.domain}`,
          JSON.stringify(dataForm)
        );

        const postProspect = async () => {
          const dataPayload = dataForm;
          dataPayload.property_id = property.id;

          const result = await serviceProspects({ dataPayload });
          console.log("handlePostProspect result:", result);

          if (result?.status === 200) {
            handlCallbackSave();
          } else if (
            result?.data?.status !== "reject" &&
            result?.status === 202
          ) {
            localStorage.setItem(
              `@${config.imob.domain}`,
              JSON.stringify(result.data)
            );

            inputName.current.value = "";
            inputEmail.current.value = "";
            inputPhone.current.value = "";

            setDataFormResult(null);
            handlCallbackSave();
          } else {
            setDataFormResult(result);
          }
        };

        postProspect();
      } else {
        setRecaptchaAlert("Atividade suspeita detectada por Google ReCaptcha");
      }

      Helper.consoleEnd();
    } catch (e) {
      console.log(e);
    }
  };

  const goPage = () => history.push('/financiamento');

  return (
    <>
      <div className="CaptureInfosButtonContainer">
        <div className="CaptureInfosButtonContent">
          <a
            className="CaptureInfosLink"
            title='Financiamento'
            onClick={goPage}
          >
            Simule seu financiamento aqui!
          </a>
        </div>
      </div>

      {/* <div className="CaptureInfosContainer"> */}
      <div className="CaptureInfosButtonContainer">
        <div className="CaptureInfosButtonContent">
          <button
            className="CaptureInfosButtonOpen"
            type="button"
            onClick={handleOpenModal}
          >
            Deixe-nos entrar em contato com você. 
            <br/>
            Podemos encontrar imóveis similares ao que você procura!
          </button>
        </div>
      </div>
      {/* </div> */}

      {openModal && (
        <>
          <GoogleReCaptchaProvider
            reCaptchaKey={config?.recaptcha?.api_public_key}
            language="pt-BR"
            useRecaptchaNet="false"
          >
            <div className="CaptureInfosContainer">
              <div className="CaptureInfosContent">
                <form onSubmit={handlePostProspect} method="POST">
                  <a
                    type="button"
                    className="CaptureInfosButtonClose"
                    onClick={handleCloseModal}
                  >
                    <AiOutlineCloseCircle className="CaptureInfosButtonCloseIcon" />
                  </a>

                  {recaptchaAlert && (
                    <div className="CaptureInfosFormGroup">
                      <Alert type="Danger" text={recaptchaAlert} />
                    </div>
                  )}

                  <div className="CaptureInfosFormGroup">
                    {!recaptchaToken && <GoogleReCaptcha
                      onVerify={(token) => {
                        // console.log("GoogleReCaptcha token:", token);
                        setRecaptchaToken(token);
                      }}
                    />}
                  </div>

                  <div className="CaptureInfosFormGroup">
                    {dataFormResult?.errors?.name && (
                      <span className="CaptureInfosInputError">
                        {dataFormResult.errors.name}
                      </span>
                    )}
                    <input
                      className={`CaptureInfosInput`}
                      type="text"
                      name="name"
                      placeholder="Seu nome..."
                      onChange={handleSetDataForm}
                      ref={inputName}
                    />
                  </div>
                  <div className="CaptureInfosFormGroup">
                    {dataFormResult?.errors?.email && (
                      <span className="CaptureInfosInputError">
                        {dataFormResult.errors.email}
                      </span>
                    )}
                    <input
                      className="CaptureInfosInput"
                      type="text"
                      name="email"
                      placeholder="Seu email..."
                      onChange={handleSetDataForm}
                      ref={inputEmail}
                    />
                  </div>
                  <div className="CaptureInfosFormGroup">
                    {dataFormResult?.errors?.phone && (
                      <span className="CaptureInfosInputError">
                        {dataFormResult.errors.phone}
                      </span>
                    )}
                    <input
                      className="CaptureInfosInput"
                      type="text"
                      name="phone"
                      placeholder="Seu telefone..."
                      onChange={handleSetDataForm}
                      ref={inputPhone}
                    />
                  </div>
                  <div className="CaptureInfosFormGroup">
                    {recaptchaToken && (
                      <button
                        type="submit"
                        className="CaptureInfosButtonSend"
                        // onClick={handlePostProspect}
                      >
                        Enviar
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </GoogleReCaptchaProvider>
          <div className="CaptureInfosBackdrop"></div>
        </>
      )}
    </>
  );
};

export default CaptureInfos;
