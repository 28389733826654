/**
 * Console Labels.
 */
export const consoleBeggin = (label) => {
  const fullLabel = label ? `>>>> ${label}` : ">>>>";
  console.log("");
  console.log(fullLabel);
};

export const consoleEnd = (label) => {
  const fullLabel = label ? `<<<< ${label}` : "<<<<";
  console.log(fullLabel);
  console.log("");
};

/**
 * Return URL trated hostname.
 */
export const getHostname = () => window.location.hostname.replace("www.", "");

/**
 * Set encode query string URL.
 */
export const setParamsUrlFilter = ({
  states,
  cities,
  neighborhoods,
  bedrooms,
  garages,
  types,
  priceMin,
  priceMax,
  page,
  orderBy,
}) => {
  try {
    const queryString = new URLSearchParams();

    consoleBeggin("DEBUGFILTRO");
    console.log("setParamsUrlFilter states:", states);
    console.log("setParamsUrlFilter cities:", cities);
    console.log("setParamsUrlFilter neighborhoods:", neighborhoods);
    console.log("setParamsUrlFilter bedrooms:", bedrooms);
    console.log("setParamsUrlFilter garages:", garages);
    console.log("setParamsUrlFilter types:", types);
    console.log("setParamsUrlFilter priceMin:", priceMin);
    console.log("setParamsUrlFilter priceMax:", priceMax);
    console.log("setParamsUrlFilter page:", page);
    console.log("setParamsUrlFilter orderBy:", orderBy);

    if (states?.length)
      states.map((state) => queryString.append("states[]", state));

    if (cities?.length)
      cities.map((city) => queryString.append("cities[]", city));

    if (neighborhoods?.length)
      neighborhoods.map((neighborhood) =>
        queryString.append("neighborhoods[]", neighborhood)
      );

    if (types?.length) types.map((tipo) => queryString.append("types[]", tipo));

    if (bedrooms?.length)
      bedrooms.map((dormitorio) =>
        queryString.append("bedrooms[]", dormitorio)
      );

    if (garages?.length)
      garages.map((garagem) => queryString.append("garages[]", garagem));

    if (priceMin) queryString.append("priceMin", priceMin);
    if (priceMax) queryString.append("priceMax", priceMax);

    if (page) queryString.append("page", page);

    if (orderBy) queryString.append("orderBy", orderBy);

    console.log("setParamsUrlFilter queryString:", queryString);
    consoleEnd("DEBUGFILTRO");

    return queryString.toString();
  } catch (e) {
    console.log(e);
  }
};

/**
 * Get decode query string URL.
 */
const decodeUrlSearchParams = () => {
  try {
    const urlCurrent = window.location.search;

    let search = decodeURI(urlCurrent);
    search = search.replace(/\[\]/g, "");

    const searchParams = new URLSearchParams(search);

    return searchParams;
  } catch (e) {
    console.log(e);
  }
  return null;
};

/**
 * Get decode query string URL to filter properties.
 */
export const getParamsUrlFilter = () => {
  let states = [];
  let cities = [];
  let neighborhoods = [];
  let bedrooms = [];
  let garages = [];
  let types = [];
  let priceMin = [];
  let priceMax = [];
  let page = 1;
  let orderBy = "";

  try {
    const searchParams = decodeUrlSearchParams();

    states = searchParams.getAll("states");
    cities = searchParams.getAll("cities");
    neighborhoods = searchParams.getAll("neighborhoods");
    types = searchParams.getAll("types");
    bedrooms = searchParams.getAll("bedrooms");
    garages = searchParams.getAll("garages");
    priceMin = searchParams.getAll("priceMin");
    priceMax = searchParams.getAll("priceMax");
    page = searchParams.getAll("page");
    orderBy = searchParams.get("orderBy");

    return {
      states,
      cities,
      neighborhoods,
      bedrooms,
      garages,
      types,
      priceMin,
      priceMax,
      page,
      orderBy,
    };
  } catch (e) {
    console.log(e);
  }

  return {
    states,
    cities,
    neighborhoods,
    bedrooms,
    garages,
    types,
    priceMin,
    priceMax,
    page,
    orderBy,
  };
};

/**
 * Deep Clone object.
 */
export const deepClone = (obj) => {
  try {
    if (obj && typeof obj === "object") return JSON.parse(JSON.stringify(obj));
  } catch (e) {
    console.log(e);
  }
  return obj;
};

/**
 * Is value integer valid.
 */
export const isValidInt = (value) => value && parseInt(value);

/**
 * Is code/codePretty property valid.
 */
export const isValidCode = (value) => value && value?.length <= 8;

/**
 * Is value decimal valid.
 */
export const isValidDecimal = (value) =>
  value && parseFloat(value) && parseFloat(value) > 0;

/**
 * Date.
 */
export const getDateNow = () => {
  const date = new Date().toLocaleDateString("pt-BR", {
    timeZone: "America/Sao_Paulo",
  });
  console.log("Helper getDateNow(), Date:", date);
  return date;
};

/* Get year. */
export const getYear = () => {
  try {
    const date = getDateNow();
    return date.split("/")[2];
  } catch (e) {
    console.log(e);
  }
  return "----";
};

/**
 * Crop text string.
 */
export const cropString = (str, limit = 255) => {
  try {
    let result = "";

    if (str) result = str.toString().substring(0, limit);
    if (str?.length > limit) result = `${result}...`;

    return result;
  } catch (e) {
    console.log(e);
  }
  return str;
};
