///////
// RJS
//////
import React, { useEffect, useState, useRef } from "react";

/**
 * Google Racaptcha.
 */
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

/**
 * Icons
 */
// import { AiOutlineCloseCircle } from "react-icons/ai";

/**
 * Router DOM.
 */
//  import { useHistory } from "react-router-dom";

////////////////
// Application.
////////////////

/**
 * Config.
 */
import config from "../../config";

/**
 * Components.
 */
import Alert from "../alert";

/**
 * Helpers.
 */
import * as Helper from "../../helpers";
// import * as HOH from "../../helpers/highOrderHelper";

/**
 * Service.
 */
import { serviceProspects } from "../../services/api/prospects";

/**
 * Style.
 */
import "./styles.css";

const CaptureNews = () => {
  /*
   * Data form set.
   */
  const inputName = useRef(null);
  const inputEmail = useRef(null);

  const [dataForm, setDataForm] = useState({});
  const [dataFormResult, setDataFormResult] = useState({});

  const handleSetDataForm = (e) => {
    dataForm[e.target.name] = e.target.value;
    setDataForm(dataForm);
  };

  /**
   * Google Racaptcha.
   */
  /**
   * TODO: TOKEN TEST LOCAL
  */
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [recaptchaAlert, setRecaptchaAlert] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);

  const handlePostProspect = (e) => {
    try {
      e.preventDefault();

      Helper.consoleBeggin();

      if (recaptchaToken) {
        localStorage.setItem(
          `@${config.imob.domain}`,
          JSON.stringify(dataForm)
        );

        const postProspect = async () => {
          const dataPayload = dataForm;

          dataPayload.name = 'News';
          dataPayload.phone = '(00) 00000-0000';

          const result = await serviceProspects({ dataPayload });
          console.log("handlePostProspect result:", result);

          if (result?.status === 200) {
            inputEmail.current.value = "";
            setResponseMessage('Cadastro realizado com sucesso!');
          } else if (
            result?.data?.status !== "reject" &&
            result?.status === 202
          ) {
            localStorage.setItem(
              `@${config.imob.domain}`,
              JSON.stringify(result.data)
            );

            inputName.current.value = "";
            inputEmail.current.value = "";

            setDataFormResult(null);
          } else {
            setDataFormResult(result);
          }
        };

        postProspect();
      } else {
        setRecaptchaAlert("Atividade suspeita detectada por Google ReCaptcha");
      }

      Helper.consoleEnd();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={config?.recaptcha?.api_public_key}
        language="pt-BR"
        useRecaptchaNet="false"
      >
        <div className="CaptureNewsContainer">
          <div className="CaptureNewsContent">
            {responseMessage && (
              <span className="CaptureNewsInputSuccess">
                {responseMessage}
              </span>
            )}
            {dataFormResult?.errors?.email && (
              <span className="CaptureNewsInputError">
                {dataFormResult.errors.email}
              </span>
            )}
            <form onSubmit={handlePostProspect} method="POST">
              {recaptchaAlert && (
                <div className="CaptureNewsFormGroup">
                  <Alert type="Danger" text={recaptchaAlert} />
                </div>
              )}

              <div className="CaptureNewsFormGroup">
                {!recaptchaToken && <GoogleReCaptcha
                  onVerify={(token) => {
                    // console.log("GoogleReCaptcha token:", token);
                    setRecaptchaToken(token);
                  }}
                />}
              </div>

              <div className="CaptureNewsFormGroup">
                <input
                  className="CaptureNewsInput"
                  type="text"
                  name="email"
                  placeholder="Seu email..."
                  onChange={handleSetDataForm}
                  ref={inputEmail}
                />
              </div>
              <div className="CaptureNewsFormGroup">
                {recaptchaToken && (
                  <button
                    type="submit"
                    className="CaptureNewsButtonSend"
                    // onClick={handlePostProspect}
                  >
                    Enviar
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </GoogleReCaptchaProvider>
    </>
  );
};

export default CaptureNews;
