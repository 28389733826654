///////
// RJS
//////
import React, { useState, useRef, useEffect } from "react";

/**
 * Application.
 */

/**
 * React Icons.
 */
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

/**
 * Styles.
 */
import "./styles.css";

const PrettySelect = ({
  title = "",
  placeholder = "",
  data = [],
  dataSelected = [],
  keyId = "id",
  keyDesc = "name",
  handleSelectOption = () => {},
  handleUnselectOption = () => {},
  hasGroup = false,
}) => {
  /**
   * Handle Click Option.
   */
  const onSelectOption = (item) => handleSelectOption(item);
  const onUnselectOption = (item) => handleUnselectOption(item);

  /**
   * Check if option has selected.
   */
  const hasInclude = (item) => dataSelected.includes(item[keyId].toString());

  /**
   * Search input.
   */
  const [dataSearch, setDataSearch] = useState(undefined);

  const handleSearchOption = (e) => {
    try {
      const text = e?.target?.value;

      let newData = [];
      if (hasGroup) {
        data.map((group) => {
          newData = group[1].filter(
            (item) =>
              item[keyDesc].toString().search(new RegExp(text, "i")) > -1
          );
        });
      } else {
        newData = data.filter(
          (item) => item[keyDesc].toString().search(new RegExp(text, "i")) > -1
        );
      }

      const newDataSearch = [];
      newData.map((item) => newDataSearch.push(item[keyId].toString()));

      setDataSearch(newDataSearch ? newDataSearch : undefined);
    } catch (e) {
      console.log(e);
    }
  };

  const hasDataSearch = (item) => {
    if (dataSearch !== undefined)
      return dataSearch.includes(item[keyId].toString());
    return true;
  };

  /**
   * Ref input.
   */
  const classicSelectInput = useRef(null);

  /**
   * Handler Open/Close list options.
   */
  const clickOutCloseOptions = useRef(null)
  const [openOptions, setOpenOptions] = useState(false);

  const handleOpenOptions = () => setOpenOptions(true);
  // eslint-disable-next-line
  const handleCloseOptions = (e) => {
    try {
      // console.log('DEBUG mousedown e.target:', e.target);
      // console.log('DEBUG mousedown clickOutCloseOptions.current:', clickOutCloseOptions.current);
      if (clickOutCloseOptions.current && clickOutCloseOptions.current.contains(e.target)) return
      setTimeout(() => {
        classicSelectInput.current.value = "";
        setDataSearch("");
        handleSearchOption("");
        setOpenOptions(false); 
      }, 100);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      if (openOptions) {
        document.addEventListener('mousedown', handleCloseOptions)
      } else {
        document.removeEventListener('mousedown', handleCloseOptions)
      }
      return () => {
        // Limpa a assinatura antes do componente deixar a tela
        // ... ou seja, dispara esta função quando o componente é destruído.
        // console.log('DEBUG mousedown: Limpa a assinatura antes do componente deixar a tela');
        document.removeEventListener('mousedown', handleCloseOptions)
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [openOptions])

  return (
    <div className="PrettySelectContainer">
      <div className="PrettySelectContent">
        <div className="PrettySelectHeader">
          <h5 className="PrettySelectHeaderTitle">{title}</h5>
        </div>
        <div className="PrettySelectBody" ref={clickOutCloseOptions}>
          <div className="PrettySelectSelectedContainer">
            {hasGroup
              ? !!data?.length &&
                data.map(
                  (groupData) =>
                    groupData?.length &&
                    groupData[1].map(
                      (item) =>
                        hasInclude(item) && (
                          <div
                            className="PrettySelectSelectedItem"
                            key={item[keyId]}
                          >
                            <span className="PrettySelectSelectedItemDesc">
                              {item[keyDesc]}
                            </span>
                            <span
                              className="PrettySelectSelectedItemButton"
                              onClick={() => onUnselectOption(item)}
                              type="button"
                            >
                              x
                            </span>
                          </div>
                        )
                    )
                )
              : !!data?.length &&
                data.map(
                  (item) =>
                    hasInclude(item) && (
                      <div
                        className="PrettySelectSelectedItem"
                        key={item[keyId]}
                      >
                        <span className="PrettySelectSelectedItemDesc">
                          {item[keyDesc]}
                        </span>
                        <span
                          className="PrettySelectSelectedItemButton"
                          onClick={() => onUnselectOption(item)}
                          type="button"
                        >
                          x
                        </span>
                      </div>
                    )
                )}
          </div>
          <div className="PrettySelectOptionsContainer">
            <div className="PrettySelectOptionsInputContainer">
              <input
                ref={classicSelectInput}
                className="PrettySelectOptionsInput"
                onFocus={handleOpenOptions}
                // onBlur={handleCloseOptions}
                onKeyUp={handleSearchOption}
                type="text"
                placeholder={placeholder}
              />
              {openOptions && (
                <FaAngleUp
                  className="ClassicSelectOptionsIcon"
                  onClick={handleCloseOptions}
                />
              )}
              {!openOptions && (
                <FaAngleDown
                  className="ClassicSelectOptionsIcon"
                  onClick={handleOpenOptions}
                />
              )}
            </div>
            <div className="PrettySelectOptionsListContainer">
              {openOptions && (
                <ul className="PrettySelectOptionsList">
                  {hasGroup
                    ? !!data?.length &&
                      data.map((groupData, i) => {
                        return (
                          <div key={String(i)}>
                            <li className="PrettySelectOption optionGroupTitle">
                              {groupData[0]}
                            </li>

                            {!!groupData[1]?.length &&
                              groupData[1].map((item) => {
                                return (
                                  hasDataSearch(item) && (
                                    <li
                                      className="PrettySelectOption"
                                      onClick={() => onSelectOption(item)}
                                      key={item[keyId]}
                                    >
                                      {item[keyDesc]}
                                    </li>
                                  )
                                );
                              })}
                          </div>
                        );
                      })
                    : !!data?.length &&
                      data.map(
                        (item) =>
                          hasDataSearch(item) && (
                            <li
                              className="PrettySelectOption"
                              key={item[keyId]}
                              onClick={() => onSelectOption(item)}
                            >
                              {item[keyDesc]}
                            </li>
                          )
                      )}
                </ul>
              )}
              {/* {openOptions && (
                <button
                  className="PrettySelectOptionsListCloseButton"
                  onClick={handleCloseOptions}
                >
                  {title} Ok
                </button>
              )} */}
            </div>
          </div>
        </div>
        <div className="PrettySelectFooter"></div>
      </div>
    </div>
  );
};

export default PrettySelect;
