import config from "../../config";
import { POST } from "../http";

/**
 * Register property agency.
 */
export const servicePropertiesAgency = async ({ dataPayload }) => {
  let data_response = {};

  try {
    const dataForm = dataPayload;
    const url = `${config.baseUrlApi}/pub/${config.imob.domain}/properties/agency`;
    data_response = await POST({ url, dataForm });
    return data_response;
  } catch (e) {
    console.log(e);
  }

  return data_response;
};
