///////
// RJS
///////
import React, { useEffect } from "react";

///////////////
// Application
///////////////

/**
 * Config.
 */
import config from "../../config";

/**
 * Helpers.
 */
import * as Helper from "../../helpers";

/**
 * Services.
 */
import { servicePostAnalytics } from "../../services/api/analytics";

const Analytics = ({ property }) => {
  /**
   * Post service.
   */
  const postAnalytics = async () => {
    Helper.consoleBeggin();
    const { hostname, pathname, search } = window.location;

    const payload = {
      hostname: hostname,
      pathname: pathname,
      search: search,
    };

    let prospect = localStorage.getItem(`@${config.imob.domain}`);
    if (prospect) prospect = JSON.parse(prospect);
    if (prospect?.email) payload.prospect_index = prospect?.email;

    if (property?.code) {
      payload.property_code = property.code;
      payload.codePretty = property.codePretty;
      payload.property_id = property.id;
    }

    const result = await servicePostAnalytics(payload);
    // console.log("Analytics postAnalytics result:", result);

    Helper.consoleEnd();
  };

  useEffect(() => {
    // console.log("Analytics window.location:", window.location);
    try {
      postAnalytics();
    } catch (e) {
      console.log(e);
    }
  }, []);

  return "";
};

export default Analytics;
