///////
// RJS
//////
import React from "react";

/**
 * Router DOM.
 */
// import { Link } from "react-router-dom";

/**
 * Libs.
 */
import { FaPaperPlane } from "react-icons/fa";

//////////////
// Application
//////////////

/**
 * Helpers.
 */
import * as HOH from "../../../helpers/highOrderHelper";

/**
 * Assets.
 */
import avatarBroker from "../../../assets/schenckelni-logo.png";

/**
 * Config.
 */
import config from "../../../config";

/**
 * Styles.
 */
import "./styles.css";

const ContactWhatsFloat = ({ text }) => {
  return (
    <div className="ContactWhatsContainerFloat">
      <div className="ContactWhatsContentFloat">
        <div className="ContactWhatsHeaderFloat">
          <img
            className="ContactWhatsHeaderFloatAvatar"
            src={avatarBroker}
            alt="Foto do corretor de imóveis"
          />
          <div className="ContactWhatsHeaderFloatDesc">
            <h2 className="ContactWhatsHeaderFloatDescTitle">Fale com um de nossos corretores</h2>
            <p className="ContactWhatsHeaderFloatDescStatus">online</p>
          </div>
        </div>
        <div className="ContactWhatsBodyFloat">
          <a
            className="ContactWhatsButtonFloat"
            href={`${HOH.openLinkWebOrDeeplinkDevice(config.social.whats)}${
              text ? "&text=" + text : ""
            }`}
            target="_blank"
            title={config.social.whats.title}
          >
            Abrir Whats
            <FaPaperPlane className="ContactWhatsButtonIconFloat" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactWhatsFloat;
