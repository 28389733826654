///////////////
// Application.
///////////////

/**
 * Enum.
 */
import { envEnum } from "./enum";

export default {
  ENVIRONMENT: envEnum.PRODUCTION,
  DOMAIN: null,
};
