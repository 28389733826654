///////
// RJS
//////
import React from "react";

///////////////
// Application
//////////////

/**
 * Styles.
 */
import "./styles.css";

const Alert = ({ type = "Default", text = "" }) => (
  <div className={`Alert Alert${type}`}>{text}</div>
);

export default Alert;
