///////
// RJS
//////
import React, { useEffect, useState, useRef } from "react";

/**
 * Google Racaptcha.
 */
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

///////////////
// Application
//////////////

/**
 * Config.
 */
import config from "../../../config";

/**
 * Components.
 */
import Alert from "../../../components/alert";

/**
 * Service.
 */
import { servicePostMail } from "../../../services/mail";
import { serviceMessages } from "../../../services/api/messages";

/**
 * Helpers.
 */
import * as Helper from "../../../helpers";

/**
 * Styles.
 */
import "./styles.css";

const ContactEmail = ({ property }) => {
  const [dataForm, setDataForm] = useState({});

  useEffect(() => {
    console.log("ContactEmail property:", property);

    if (property?.code) {
      dataForm.message = `Olá, gostaria de mais infos sobre o imóvel ${
        property?.codePretty ? property?.codePretty : property?.code
      }`;
      dataForm.subject = `Imóvel ${
        property?.codePretty ? property?.codePretty : property?.code
      }`;
    }
  }, [property]);

  const handleSetDataForm = (e) => {
    dataForm[e.target.name] = e.target.value;
    setDataForm(dataForm);
  };

  /**
   * Submit.
   */
  const [alertSuccess, setAlertSuccess] = useState(null);

  const inputName = useRef(null);
  const inputEmail = useRef(null);
  const inputPrimaryContact = useRef(null);
  const inputSubject = useRef(null);
  const inputMessage = useRef(null);

  const [dataFormResult, setDataFormResult] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    Helper.consoleBeggin();

    if (recaptchaToken) {
      console.log("ContactEmail handleSubmit, dataForm:", dataForm);

      const result = await serviceMessages({ dataForm });
      console.log("ContactEmail handleSubmit, result:", result);

      if (result?.status === 200) {
        console.log("ContactEmail handleSubmit, SUCCESS!");

        inputName.current.value = "";
        inputEmail.current.value = "";
        inputPrimaryContact.current.value = "";
        inputSubject.current.value = "";
        inputMessage.current.value = "";

        const resetDataForm = {
          subject: `Imóvel ${
            property?.codePretty ? property?.codePretty : property?.code
          }`,
          message: `Olá, gostaria de mais infos sobre o imóvel ${
            property?.codePretty ? property?.codePretty : property?.code
          }`,
        };

        setDataFormResult(null);
        setDataForm(resetDataForm);
        setAlertSuccess(
          "Email enviado com sucesso. Em breve entreraremos em contato!"
        );
      } else {
        setDataFormResult(result);
      }
    } else {
      setRecaptchaAlert("Atividade suspeita detectada por Google ReCaptcha");
    }

    Helper.consoleEnd();
  };

  /**
   * Google Racaptcha.
   */
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [recaptchaAlert, setRecaptchaAlert] = useState(null);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={config?.recaptcha?.api_public_key}
      language="pt-BR"
      useRecaptchaNet="false"
    >
      <div className="ContactEmailContainer">
        <div className="ContactEmailContent">
          <h3 className="ContactEmailContentTitle">Tenho interesse neste imóvel</h3>

          <form onSubmit={handleSubmit} method="POST">
            {recaptchaAlert && (
              <div className="CaptureInfosFormGroup">
                <Alert type="Danger" text={recaptchaAlert} />
              </div>
            )}

            <div className="ContactEmailFormGroup">
              {!recaptchaToken && <GoogleReCaptcha
                onVerify={(token) => {
                  // console.log("GoogleReCaptcha token:", token);
                  setRecaptchaToken(token);
                }}
              />}
            </div>

            <div className="ContactEmailFormGroup">
              {dataFormResult?.errors?.name && (
                <span className="ContactEmailInputError">
                  {dataFormResult.errors.name}
                </span>
              )}
              <input
                className="ContactEmailInput"
                type="text"
                name="name"
                placeholder="Seu nome..."
                onChange={handleSetDataForm}
                ref={inputName}
              />
            </div>
            <div className="ContactEmailFormGroup">
              {dataFormResult?.errors?.email && (
                <span className="ContactEmailInputError">
                  {dataFormResult.errors.email}
                </span>
              )}
              <input
                className="ContactEmailInput"
                type="text"
                name="email"
                placeholder="Seu email..."
                onChange={handleSetDataForm}
                ref={inputEmail}
              />
            </div>
            <div className="ContactEmailFormGroup">
              <input
                className="ContactEmailInput"
                type="text"
                name="primary_contact"
                placeholder="Seu telefone..."
                onChange={handleSetDataForm}
                ref={inputPrimaryContact}
              />
            </div>
            <div className="ContactEmailFormGroup">
              {dataFormResult?.errors?.subject && (
                <span className="ContactEmailInputError">
                  {dataFormResult.errors.subject}
                </span>
              )}
              <input
                className="ContactEmailInput"
                type="text"
                name="subject"
                placeholder="Assunto..."
                onChange={handleSetDataForm}
                defaultValue={dataForm?.subject}
                ref={inputSubject}
              />
            </div>
            <div className="ContactEmailFormGroup">
              {dataFormResult?.errors?.message && (
                <span className="ContactEmailInputError">
                  {dataFormResult.errors.message}
                </span>
              )}
              <textarea
                className="ContactEmailText"
                name="message"
                onChange={handleSetDataForm}
                defaultValue={dataForm?.message}
                ref={inputMessage}
              ></textarea>
            </div>
            <div className="ContactEmailFormGroup">
              {alertSuccess && <Alert type="Success" text={alertSuccess} />}
              {!alertSuccess && (
                <button
                  type="submit"
                  className="ContactEmailButton"
                  // onClick={handleSubmit}
                >
                  Enviar
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default ContactEmail;
