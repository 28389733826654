//////////////
// Application
//////////////

/**
 * Config.
 */
import config from "../../config";

/**
 * Services.
 */
import { POST, GET } from "../http";

/**
 * Get page HOME full data endpoint.
 */
export const serviceProspects = async ({ dataPayload }) => {
  const dataForm = dataPayload;
  let data = {};

  try {
    const url = `${config.baseUrlApi}/pub/${config.imob.domain}/prospects`;
    data = await POST({ url, dataForm });
    return data;
  } catch (e) {
    console.log(e);
  }

  return data;
};

/**
 * Get page HOME full data endpoint.
 */
export const serviceGetProspect = async ({ email }) => {
  let data = {};

  try {
    const url = `${config.baseUrlApi}/pub/${config.imob.domain}/prospects/${email}`;
    data = await GET({ url });
    return data;
  } catch (e) {
    console.log(e);
  }

  return data;
};
