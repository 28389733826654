///////
// RJS
///////
import React from "react";

/**
 * React Icons.
 */
import { FaCat } from "react-icons/fa";

////////////////
// Application.
////////////////

/**
 * Styles.
 */
import "./styles.css";

const NotFound = () => {
  return (
    <div className="NotFoundContainer">
      <div className="NotFoundContent">
        <div className="NotFound">
          <h2>O que você procura não foi encontrado!</h2>
          <h3>Navegue mais um pouco no nosso site...</h3>
          <FaCat className="NotFoundIcon" />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
