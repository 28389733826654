///////
// RJS
//////
import React from "react";

/**
 * Video React.
 */
import { Player } from "video-react";

//////////////
// Application
//////////////

/**
 * Config.
 */
 import config from "../../config";

/**
 * Assets.
 */
import thumbAbout from '../../assets/thumb-about.png';

/**
 * Styles.
 */
import "./styles.css";

const QuemSomos = () => {
  return (
    <>
      <div className="QuemSomosInfosContainer">
        <div className="QuemSomosInfosContent">
          <h1 className="QuemSomosInfosTitle">{config.navigation.about.desc}</h1>
          {/* <p className="QuemSomosInfosDesc">{property?.descGeral}</p> */}
        </div>
      </div>

      <div className="QuemSomosInfosContainer PlayerVideoContainer">
        <div className="QuemSomosInfosContent noBg">
          <Player
            playsInline={false}
            fluid={true}
            poster={thumbAbout}
            src="https://imobmobile.com.br/videos/schenckelni.com.br--about.MOV"
          />
        </div>
      </div>
    </>
  );
};

export default QuemSomos;
