//////////////
// Application
//////////////

/**
 * Config.
 */
import config from "../../config";

/**
 * Services Http.
 */
import { POST } from "../http";

/**
 * Helpers.
 */
import * as Helper from "../../helpers";

/**
 * Get cities.
 */
export const serviceMessages = async ({ dataForm }) => {
  let data = {};

  try {
    const url = `${config.baseUrlApi}/pub/${config.imob.domain}/messages`;
    data = await POST({ url, dataForm });
    return data;
  } catch (e) {
    console.log(e);
  }

  return data;
};
