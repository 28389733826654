//////////////
// Application
//////////////

/**
 * Config.
 */
import config from "../../config";

/**
 * Services Http.
 */
import { GET } from "../http";

/**
 * Helpers.
 */
import * as Helper from "../../helpers";

/**
 * Get states.
 */
export const serviceGetStates = async () => {
  let data = {};

  try {
    const url = `${config.baseUrlApi}/pub/${config.imob.domain}/states`;
    data = await GET({ url });
    return data;
  } catch (e) {
    console.log(e);
  }

  return data;
};
