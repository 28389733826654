///////
// RJS
//////
import React, { useState, useEffect } from "react";

/**
 * Router DOM.
 */
// import { useParams } from "react-router-dom";

//////////////
// Application
//////////////

/**
 * Helpers.
 */
import * as Helper from "../../helpers";

/**
 * Components.
 */
import PropertyBox from "../../components/property/box";
import Filter from "../../components/filter";
import Paginate from "../../components/paginate";
import ClassicSelect from "../../components/select/classic";

/**
 * Styles.
 */
import "./styles.css";

/**
 * dataSearch INITIAL state.
 */
// const dataSearchInitial = {
//   options: {
//     states: [],
//     cities: [],
//     neighborhoods: [],
//     types: [],
//     bedrooms: [],
//     garages: [],
//     orderBy: [],
//   },
//   values_selected: {
//     states: [],
//     cities: [],
//     neighborhoods: [],
//     types: [],
//     bedrooms: [],
//     garages: [],
//     priceMin: "",
//     priceMax: "",
//     orderBy: "",
//   },
// };

const PropertiesList = () => {
  /**
   * 1 - Get filter query string URL AND get request properties result API.
   */
  const [dataSearch, setDataSearch] = useState({});
  const [dataSearchResult, setDataSearchResult] = useState([]);

  useEffect(() => {
    Helper.consoleBeggin();
    console.log("PropertiesList dataSearch:", dataSearch);
    console.log("PropertiesList dataSearchResult:", dataSearchResult);
    Helper.consoleEnd();
  }, [dataSearch, dataSearchResult]);

  /**
   * Handle Get filter properties.
   */
  const [
    urlFilterPropertiesExternal,
    setUrlFilterPropertiesExternal,
  ] = useState(null);

  const handleUrlFilterPropertiesExternal = (url) =>
    setUrlFilterPropertiesExternal(url);

  return (
    <>
      <div className="PropertiesListContainer">
        <div className="PropertiesListContent">
          <div className="PropertiesListFilterContainer">
            <Filter
              dataSearch={dataSearch}
              handleSetDataSearch={setDataSearch}
              handleDataSearchResult={setDataSearchResult}
              urlFilterPropertiesExternal={urlFilterPropertiesExternal}
            />
          </div>
          <div className="PropertiesListBoxContainer">
            <div className="PropertiesListBoxContentFilterContainer">
              <ClassicSelect
                // title="Ordenar"
                placeholder="Selecionar..."
                data={dataSearch?.options?.orderBy}
                dataSelected={dataSearch?.values_selected?.orderBy}
                handleSelectOption={(item) => {
                  dataSearch.values_selected.orderBy = item;
                  setDataSearch(Helper.deepClone(dataSearch));
                }}
                handleUnselectOption={() => {
                  // const newData = dataSearch.values_selected.states.filter(
                  //   (state) => state.toString() !== item.id.toString()
                  // );
                  // dataSearch.values_selected.states = newData;
                  // handleSetDataSearch(Helper.deepClone(dataSearch));
                  // getCities();
                }}
              />
            </div>
            <div className="PropertiesListBoxContent">
              {!!dataSearchResult?.data?.length &&
                dataSearchResult.data.map((property, i) => (
                  <PropertyBox property={property} key={String(i)} />
                ))}
            </div>
            {!!dataSearchResult?.data?.length && (
              <Paginate
                pagination={dataSearchResult?.meta?.pagination}
                handleUrlFilterPropertiesExternal={
                  handleUrlFilterPropertiesExternal
                }
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertiesList;
