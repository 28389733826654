///////
// RJS
//////
import React, { useState, useRef } from "react";

/**
 * React Icons.
 */
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

/**
 * Application.
 */

/**
 * Styles.
 */
import "./styles.css";

const ClassicSelect = ({
  title = "",
  placeholder = "",
  data = [],
  dataSelected = "",
  keyId = "id",
  keyDesc = "name",
  handleSelectOption = () => {},
  hasGroup = false,
}) => {
  /**
   * Ref input.
   */
  const classicSelectInput = useRef(null);

  /**
   * Handler Open/Close list options.
   */
  const [openOptions, setOpenOptions] = useState(false);

  const handleOpenOptions = () => setOpenOptions(true);
  const handleCloseOptions = () => {
    setTimeout(() => {
      classicSelectInput.current.value = "";
      setDataSearch("");
      handleSearchOption("");
      setOpenOptions(false);
    }, 100);
  };

  /**
   * Handle Click Option.
   */
  const onSelectOption = (item) => {
    classicSelectInput.current.value = "";
    setDataSearch("");
    handleSearchOption("");
    setOpenOptions(false);
    handleSelectOption(item[keyId]);
  };

  /**
   * Search input.
   */
  const [dataSearch, setDataSearch] = useState(undefined);

  const handleSearchOption = (e) => {
    try {
      const text = e?.target?.value ? e.target.value : e;

      let newData = [];
      if (hasGroup) {
        data.map((group) => {
          newData = group[1].filter(
            (item) =>
              item[keyDesc].toString().search(new RegExp(text, "i")) > -1
          );
        });
      } else {
        newData = data.filter(
          (item) => item[keyDesc].toString().search(new RegExp(text, "i")) > -1
        );
      }

      const newDataSearch = [];
      newData.map((item) => newDataSearch.push(item[keyId].toString()));

      setDataSearch(newDataSearch ? newDataSearch : undefined);
    } catch (e) {
      console.log(e);
    }
  };

  const hasDataSearch = (item) => {
    if (dataSearch !== undefined)
      return dataSearch.includes(item[keyId].toString());
    return true;
  };

  return (
    <div
      className={`ClassicSelectContainer ${
        dataSelected ? "ClassicSelectOptionsInputHasValue" : ""
      }`}
    >
      <div className="ClassicSelectContent">
        <div className="ClassicSelectHeader">
          <h5 className="ClassicSelectHeaderTitle">{title}</h5>
        </div>
        <div className="ClassicSelectBody">
          <div className="ClassicSelectOptionsContainer">
            <div className="ClassicSelectOptionsInputContainer">
              <input
                ref={classicSelectInput}
                className="ClassicSelectOptionsInput"
                onFocus={handleOpenOptions}
                onBlur={handleCloseOptions}
                onKeyUp={handleSearchOption}
                type="text"
                placeholder={
                  dataSelected
                    ? data.find((item) => item[keyId] === dataSelected)[keyDesc]
                    : placeholder
                }
                // defaultValue={dataSelected}
              />
              {openOptions && (
                <FaAngleUp
                  className="ClassicSelectOptionsIcon"
                  onClick={handleCloseOptions}
                />
              )}
              {!openOptions && (
                <FaAngleDown
                  className="ClassicSelectOptionsIcon"
                  onClick={handleOpenOptions}
                />
              )}
            </div>
            <div className="ClassicSelectOptionsListContainer">
              {openOptions && (
                <ul className="ClassicSelectOptionsList">
                  {data.map(
                    (item, i) =>
                      hasDataSearch(item) && (
                        <li
                          key={String(i)}
                          className="ClassicSelectOption"
                          onClick={() => onSelectOption(item)}
                        >
                          {item[keyDesc]}
                        </li>
                      )
                  )}
                </ul>
              )}
              {/* {openOptions && (
                <button
                  className="ClassicSelectOptionsListCloseButton"
                  onClick={handleCloseOptions}
                >
                  Fechar
                </button>
              )} */}
            </div>
          </div>
        </div>
        <div className="ClassicSelectFooter"></div>
      </div>
    </div>
  );
};

export default ClassicSelect;
