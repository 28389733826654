///////
// RJS
//////
import React from 'react';

 /**
 * Styles.
 */
import "./styles.css";

const FinancingBox = ({
  data
}) => (
  <div className="FinancingBoxContainer">
    <div className="FinancingBoxContent">
      <div className="FinancingBox">
        <a href={data?.link} title={`Ir para ${data?.name}`} target='_blank'>
          <img
            className='FinancingBoxImg'
            src={data?.image}
            alt={`Foto ${data?.name}`}
          />
        </a>
      </div>
    </div>
  </div>
);

export default FinancingBox;