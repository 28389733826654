///////
// RJS
///////
import React, { useEffect, useState } from "react";
import { Redirect, Link } from "react-router-dom";

//////////////
//Application.
//////////////

/**
 * Config.
 */
import config from "../../config";

/**
 * Helpers.
 */
import * as Helper from "../../helpers";

/**
 * Styles.
 */
import "./styles.css";

const Paginate = ({
  pagination = {},
  handleUrlFilterPropertiesExternal = () => {},
}) => {
  const [pageLinks, setPageLinks] = useState([]);

  useEffect(() => {
    try {
      Helper.consoleBeggin();
      console.log("Paginate pagination:", pagination);

      const { total_pages, links } = pagination;

      console.log("Paginate total_pages:", total_pages);
      console.log("Paginate links:", links);

      const newPageLinks = [];

      if (total_pages && total_pages > 1) {
        for (let i = 1; total_pages >= i; i++) {
          console.log("Paginate i:", i);
          newPageLinks.push(
            `${config.navigation.properties.link}${links?.base}&page=${i}`
          );
        }
      }

      console.log("Paginate newPageLinks:", newPageLinks);
      setPageLinks(newPageLinks);

      Helper.consoleEnd();
    } catch (e) {
      console.log(e);
    }
  }, [pagination]);

  /**
   * Handle go page.
   */
  const handleGoPage = (page) => handleUrlFilterPropertiesExternal(page);

  return (
    <div className="PaginateContainer">
      <div className="PaginateContent">
        <ul className="PaginateContentList">
          {pageLinks.map((page, i) => (
            <li
              className={`PaginateContentPage ${
                i + 1 === pagination?.current_page ? "active" : ""
              }`}
              key={String(i)}
            >
              <Link
                className="PaginateContentLink"
                to={page}
                onClick={() => handleGoPage(page)}
                title="Ir para page"
              >
                {i + 1}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Paginate;
