///////////////
// Application.
///////////////

/**
 * Enum.
 */
import { envEnum } from "../enum";

/**
 * Helpers.
 */
import { consoleBeggin, consoleEnd, getHostname } from "../helpers";

/**
 * .env
 */
import ENV from "../.env";

/**
 * Data config retuned.
 */
const config = {
  /**
   * Application.
   */

  /* Base. */
  baseUrlNavigation: "",

  /**
   * API.
   */

  /* Base URL. */
  baseUrlApi: "",

  /**
   * CDN: Content Delivery Network.
   */

  /* CDN Base. */
  baseUrlCdn: "",

  /* CDN Photos. */
  photoThumb: "",
  photoNormal: "",

  /**
   * Data Imob.
   */
  imob: {
    domain: "",
    name: "Schenckel Negócios Imobiliários",
    abbr: "Schenckel NI",
    limitPhotosShow: 4,
  },

  /**
   * Navigation URLs.
   */
  navigation: {
    root: {
      link: "/",
      desc: "Home",
    },
    home: {
      link: "/",
      desc: "Home",
    },
    properties: {
      link: "/imoveis",
      desc: "Imóveis",
    },
    property: {
      link: "/imovel",
      desc: "Detalhes",
    },
    contact: {
      link: "/contact",
      desc: "Contato",
    },
    financing: {
      link: "/financiamento",
      desc: "Financiamento",
    },
    about: {
      link: "/quem-somos",
      desc: "Quem Somos",
    },
    notFound: {
      link: "/404",
      desc: "Nada Encontrado",
    },
  },

  /**
   * Social Medias.
   */
  social: {
    whats: {
      desc: "(51) 99989-3761",
      link: "https://web.whatsapp.com/send?phone=5551999893761",
      link_device: "whatsapp://send?phone=5551999893761",
      title: "Chamar no Whatsapp",
      alt: "Whatsapp ícone",
    },
    insta: {
      desc: "schenckelnegociosimobiliarios",
      link: "https://www.instagram.com/schenckelnegociosimobiliarios/",
      title: "Ver o Instagram",
      alt: "Instagram ícone",
    },
    face: {
      desc: "schenckelnegociosimobiliarios",
      link: "https://www.facebook.com/schenckelnegociosimobiliarios/",
      title: "Ver o Facebook",
      alt: "Facebook ícone",
    },
    in: {
      desc: "schenckelnegóciosimobiliários",
      link: "http://linkedin.com/in/schenckelnegóciosimobiliários",
      title: "Ver o LinkeIn",
      alt: "LinkeIn ícone",
    },
    email: {
      desc: "schenckel@schenckelimoveis.com.br",
      link: "mailto:schenckel@schenckelimoveis.com.br",
      title: "Enviar Email",
      alt: "Email ícone",
    },
    phone: {
      desc: "(51) 99177.7387",
      link: "tel:5551991777387",
      title: "Ligar",
      alt: "Telefone ícone",
    },
    phones: [
      {
        desc: "(51) 99989.3761",
        link: "https://web.whatsapp.com/send?phone=5551999893761",
        title: "Ligar",
        alt: "Telefone ícone",
      },
      // {
      //   desc: "(51) 3939.5005",
      //   link: "tel:555139395005",
      //   title: "Ligar",
      //   alt: "Telefone ícone",
      // },
    ],
  },

  /**
   * Endpoints API.
   */
  endpoint: {},

  /**
   * Google ReCaptcha.
   */
  recaptcha: {
    api_public_key: "6LccLacZAAAAANcZ7_ksOvReSCsMpW95H_BAOxf_",
  },
};

switch (ENV.ENVIRONMENT) {
  case envEnum.LOCALHOST:
    config.baseUrlNavigation = "http://127.0.0.1:8000";
    config.baseUrlApi = `${config.baseUrlNavigation}/api`;
    config.baseUrlCdn = config.baseUrlNavigation;
    break;

  case envEnum.LOCALNETWORK:
    config.baseUrlNavigation = "";
    config.baseUrlApi = `http://192.168.0.102:8000/api`;
    config.baseUrlCdn = "";
    break;

  case envEnum.HOMOLOGATION:
    // ...
    break;

  case envEnum.PRODUCTION:
    config.baseUrlNavigation = "";
    config.baseUrlApi = `https://imobmobile.com.br/api`;
    config.baseUrlCdn = config.baseUrlNavigation;
    break;
}

config.photoThumb = `${config.baseUrlCdn}/photos/thumb/`;
config.photoNormal = `${config.baseUrlCdn}/photos/normal/`;

/**
 * Set Imob Infos.
 */
if (ENV.DOMAIN) config.imob.domain = ENV.DOMAIN;
else config.imob.domain = getHostname();

/**
 * Navigation URLs.
 */
// config.navigation.imoveis = `${config.baseUrlNavigation}/imoveis`;

/**
 * Endpoints API.
 */
config.endpoint.properties = `${config.baseUrlApi}/pub/${config.imob.domain}/page/properties`;

/**
 * Mail.
 */
config.endpoint.mail = `${config.baseUrlNavigation}/back/mail`;

//

if (ENV.ENVIRONMENT !== envEnum.PRODUCTION) {
  consoleBeggin("CONFIG");
  console.log("DEBUGCONFIG config:", config);
  consoleEnd("CONFIG");
}

export default config;
