///////
// RJS
//////
import React from "react";

////////////////
// Application.
////////////////

/**
 * Assets.
 */
import iconExclusividade from "../../assets/icon-s.png";

/**
 * Style.
 */
import "./styles.css";

const Exclusividade = () => (
  <div className="ExclusividadeContainer">
    <div className="ExclusividadeContent">
      <img
        className="ExclusividadeIcon"
        src={iconExclusividade}
        alt="Ícone de Exclusividade"
      />
      <span className="ExclusividadeLabel">Exclusivo</span>
    </div>
  </div>
);

export default Exclusividade;
