///////
// RJS
//////
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


/**
 * Routes.
 */
// import { Link } from "react-router-dom";

//////////////
// Application
//////////////

/**
 * Config.
 */
 import config from "../../config";

/**
 * Services.
 */
import { serviceGetFeaturedProperties } from "../../services/api/properties";

/**
 * Helpers.
 */
// import * as HOH from "../../helpers/highOrderHelper";

/**
 * Styles.
 */
import "./styles.css";

/**
 * Components.
 */
import PropertyRow from "../../components/property/row";

/**
 * Hooks.
*/
import useScript from '../../hooks/useScript';

const Home = () => {
  useScript(`gtag('event', 'conversion', {'send_to': 'AW-10897067416/8mXlCM7DtLoDEJibkMwo'});`);

  /**
   * Set Featured Properties.
   */
  const [featuredProperties, setFeaturedProperties] = useState({});

  /**
   * Get Featured Properties.
   */
  const getFeaturedProperties = async () => {
    try {
      const result = await serviceGetFeaturedProperties();
      const dataLimit = result.data.slice(0, 5);
      setFeaturedProperties({ data: dataLimit });
    } catch (error) {
      console.log('DEBUG:', error);
    }
  };

  useEffect(() => {
    getFeaturedProperties();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Home">
      <div className="HomeContent">
        {/**
         * Properties Style Row.
         */}
        <div className="HomeContentProperties">
          {!!featuredProperties?.data?.length &&
            featuredProperties?.data?.map((property, i) => (
              <PropertyRow property={property} key={String(i)} />
            ))}
        </div>

        <div className="HomeContentProperties-below">
          <Link
            className="FilterButtonSubmit FilterButtonSubmit--todos-imoveis"
            to={config.navigation.properties.link}
          >
            Conferir todos os imóveis!
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;
