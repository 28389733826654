//////////////
// RJS
//////////////
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

//////////////
// Application
//////////////

/**
 * Libs.
 */
 import { FaPhoneAlt, FaInstagram, FaFacebook, FaEnvelope } from "react-icons/fa";
 import { MdWatchLater } from "react-icons/md";

/**
 * Components.
 */
import ContactWhatsFloat from "../../components/contact/contactWhatsFloat";
import CaptureNews from '../../components/CaptureNews';

/**
 * Helpers.
 */
import * as Helper from "../../helpers";

/**
 * Config.
 */
import config from "../../config";

/**
 * Assets.
*/
import logoFull from "../../assets/schenckelni-logo-full.png";

/**
 * Styles.
 */
import "./styles.css";

const Footer = () => {
  useEffect(() => {
    Helper.getDateNow();
  }, []);

  return (
    <div className="FooterContainer">
      <div className="FooterContent">
        <div className="Footer">
          <div className="FooterCol FooterColLeft">
            <h4>
              <MdWatchLater className="FooterColTitleIcon FooterColTitleIcon-watch" /> 
              <br/> 
              Horários de funcionamento
            </h4>
            <p>De segunda a sexta das 09:00 às 18:30</p>
          </div>
          <div className="FooterCol FooterColCenter">
            <h4><FaPhoneAlt className="FooterColTitleIcon" /> <br/> Informações de contato</h4>
            <p>(51) 99989-3761</p>
            <p style={{ display: 'none' }}>(51) 3939.5005</p>
            <p><FaEnvelope className="FooterColItemIcon" /> jaqueline@schenckelimoveis.com.br</p>
            <p>
              <a href="https://instagram.com/schenckelnegociosimobiliarios?igshid=YmMyMTA2M2Y=" title="Instagram" target="_blank">
                <FaInstagram className="FooterColItemIcon" /> @schenckelnegociosimobiliarios
              </a>
            </p>
            <p>
              <a href="https://www.facebook.com/schenckelnegociosimobiliarios" title="Facebook" target="_blank">
                <FaFacebook className="FooterColItemIcon" /> Schenckel Negócios Imobiliários
              </a>
            </p>
          </div>
          <div className="FooterCol FooterColRight">
            <img src={logoFull} alt="Logo Schenckel NI" className="FooterLogo" />
            <p>Rua Sapiranga, 90 - sala 403 <br/> Jardim Mauá, Novo Hamburgo <br/> CEP 93548-192</p>
            {/* <p>Jardim Mauá, Novo Hamburgo</p> */}
            <CaptureNews />
          </div>

          {/* <div className="FooterContactSocialMediaContainer"> */}
            {/* <ContactSocialMedia /> */}
          {/* </div>
          <div className="FooterContactMapContainer"> */}
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5721.154061335902!2d-51.12027303331408!3d-29.681716453266382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951943c985d8b525%3A0xced452ada77db351!2sSchenckel%20Neg%C3%B3cios%20Imobili%C3%A1rios!5e0!3m2!1spt-BR!2sbr!4v1592085817734!5m2!1spt-BR!2sbr"
              // width="600"
              // height="450"
              frameborder="0"
              // style="border:0;"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe> */}
          {/* </div> */}
        </div>
      </div>
      <div className="FooterCopyRight">
        <p className="FooterCopyContent">
          ©{Helper.getYear()} - {config.imob.abbr}
        </p>
      </div>

      <ContactWhatsFloat />
    </div>
  );
};

export default Footer;
