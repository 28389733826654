///////
// RJS
//////
import React, { useEffect, useState, useRef } from "react";

/**
 * Thirdy libs
 */
import Switch from "react-switch";

/**
 * Google Racaptcha.
 */
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

/**
 * Icons
 */
import { AiOutlineCloseCircle } from "react-icons/ai";

/**
 * React router dom.
 */

/**
 * Link: Link prevent refresh page implemented a real redirect in applications SIGLE PAGE APPLICATION.
 */
 import { Link } from "react-router-dom";

////////////////
// Application.
////////////////

/**
 * Config.
 */
import config from "../../config";

/**
 * Components.
 */
import Alert from "../../components/alert";

/**
 * Helpers.
 */
import * as Helper from "../../helpers";
import * as HOH from "../../helpers/highOrderHelper";

/**
 * Service.
 */
import { servicePropertiesAgency } from "../../services/api/propertiesAgency";

/**
 * Style.
 */
import "./styles.css";

const PropertiesAgency = ({
  dataProspect,
  property,
  handlCallbackSave = () => {
    console.log("handlCallbackSave props DEFAULT.");
  },
}) => {
  const [openModal, setOpenModal] = useState(false);

  /**
   * Data Visitor.
   */
  useEffect(() => {}, [dataProspect]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  /*
   * Data form set.
   */
  const inputOwner = useRef(null);
  const inputOwnerPhone = useRef(null);
  const inputOwnerEmail = useRef(null);
  const inputState = useRef(null);
  const inputCity = useRef(null);
  const inputNeighborhood = useRef(null);
  const inputAddress = useRef(null);
  const inputTipo = useRef(null);
  const inputDormitorio = useRef(null);
  const inputGaragem = useRef(null);
  const inputObs = useRef(null);

  const [dataForm, setDataForm] = useState({});
  const [dataFormResult, setDataFormResult] = useState({});
  const [messageSuccess, setMessageSuccess] = useState(null);

  const handleSetDataForm = (e) => {
    dataForm[e.target.name] = e.target.value;
    setDataForm(dataForm);
  };

  /**
   * Google Racaptcha.
   */
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [recaptchaAlert, setRecaptchaAlert] = useState(null);

  const handlePostPropertyAgency = (e) => {
    try {
      e.preventDefault();

      Helper.consoleBeggin();

      if (recaptchaToken) {
        // localStorage.setItem(
        //   `@${config.imob.domain}PropertyAgency`,
        //   JSON.stringify(dataForm)
        // );

        const postPropertyAgency = async () => {
          const dataPayload = dataForm;

          const serviceResponse = await servicePropertiesAgency({
            dataPayload,
          });
          console.log("handlePostProspect serviceResponse:", serviceResponse);

          if (serviceResponse?.status === 200) {
            inputOwner.current.value = "";
            inputOwnerPhone.current.value = "";
            inputOwnerEmail.current.value = "";
            inputState.current.value = "";
            inputCity.current.value = "";
            inputNeighborhood.current.value = "";
            inputAddress.current.value = "";
            inputTipo.current.value = "";
            inputDormitorio.current.value = "";
            inputGaragem.current.value = "";
            inputObs.current.value = "";

            setDataForm({});
            setDataFormResult(null);
            setMessageSuccess(true);

            setTimeout(() => {
              handlCallbackSave();
              handleCloseModal();
            }, 2000);
          } else if (serviceResponse?.status === 202) {
            setDataFormResult(serviceResponse.result);
          } else {
            setDataFormResult(serviceResponse);
          }
        };

        postPropertyAgency();
      } else {
        setRecaptchaAlert("Atividade suspeita detectada por Google ReCaptcha");
      }

      Helper.consoleEnd();
    } catch (e) {
      console.log(e);
    }
  };

  const handleSwitchChange = (checked, inputName) =>
    setDataForm({ ...dataForm, [inputName]: checked });

  useEffect(() => {
    console.log(`PropertyAgency dataForm:`, dataForm);
    console.log(`PropertyAgency dataFormResult:`, dataFormResult);
  }, [dataForm, dataFormResult]);

  return (
    <>
      <div className="PropertyAgencyButtonContainer">
        <div className="PropertyAgencyButtonContent">
          <Link
            className="PropertyAgencyButtonOpen"
            to={config.navigation.about.link}
            title={HOH.getNavigationTitle(config.navigation.about.desc)}
          >
            {config.navigation.about.desc}
          </Link>
        </div>
      </div>
      <div className="PropertyAgencyButtonContainer AnuncieSeuImovel">
        <div className="PropertyAgencyButtonContent">
          <button
            className="PropertyAgencyButtonOpen"
            type="button"
            onClick={handleOpenModal}
          >
            Anuncie o seu imóvel
          </button>
        </div>
      </div>

      {openModal && (
        <>
          <GoogleReCaptchaProvider
            reCaptchaKey={config?.recaptcha?.api_public_key}
            language="pt-BR"
            useRecaptchaNet="false"
          >
            <div className="PropertyAgencyContainer">
              <div className="PropertyAgencyContent">
                <form onSubmit={handlePostPropertyAgency} method="POST">
                  <a
                    type="button"
                    className="PropertyAgencyButtonClose"
                    onClick={handleCloseModal}
                  >
                    <AiOutlineCloseCircle className="PropertyAgencyButtonCloseIcon" />
                  </a>

                  <h3 className="PropertyAgencyTitle">Cadastro</h3>
                  <div className="PropertyAgencyLabelContainer">
                    <p className="PropertyAgencyTitle">
                      Não se preocupe, vamos entrar em contato com você e
                      aprimorar as informações :)
                    </p>
                  </div>

                  {recaptchaAlert && (
                    <div className="PropertyAgencyFormGroup">
                      <Alert type="Danger" text={recaptchaAlert} />
                    </div>
                  )}

                  <div className="PropertyAgencyFormGroup">
                    {!recaptchaToken && <GoogleReCaptcha
                      onVerify={(token) => {
                        // console.log("GoogleReCaptcha token:", token);
                        setRecaptchaToken(token);
                      }}
                    />}
                  </div>

                  <div className="PropertyAgencyFormGroup">
                    {dataFormResult?.errors?.owner && (
                      <span className="PropertyAgencyInputError">
                        {dataFormResult.errors.owner}
                      </span>
                    )}
                    <input
                      className="PropertyAgencyInput"
                      type="text"
                      name="owner"
                      placeholder="Nome do Proprietário"
                      onChange={handleSetDataForm}
                      ref={inputOwner}
                    />
                  </div>
                  <div className="PropertyAgencyFormGroup">
                    {dataFormResult?.errors?.ownerPhone && (
                      <span className="PropertyAgencyInputError">
                        {dataFormResult.errors.ownerPhone}
                      </span>
                    )}
                    <input
                      className="PropertyAgencyInput"
                      type="text"
                      name="ownerPhone"
                      placeholder="Fone/Whats do Proprietário"
                      onChange={handleSetDataForm}
                      ref={inputOwnerPhone}
                    />
                  </div>
                  <div className="PropertyAgencyFormGroup">
                    {dataFormResult?.errors?.ownerEmail && (
                      <span className="PropertyAgencyInputError">
                        {dataFormResult.errors.ownerEmail}
                      </span>
                    )}
                    <input
                      className="PropertyAgencyInput"
                      type="text"
                      name="ownerEmail"
                      placeholder="Email do Proprietário"
                      onChange={handleSetDataForm}
                      ref={inputOwnerEmail}
                    />
                  </div>

                  <hr />

                  <div className="PropertyAgencyFormGroup">
                    {dataFormResult?.errors?.state && (
                      <span className="PropertyAgencyInputError">
                        {dataFormResult.errors.state}
                      </span>
                    )}
                    <input
                      className={`PropertyAgencyInput`}
                      type="text"
                      name="state"
                      placeholder="UF"
                      onChange={handleSetDataForm}
                      ref={inputState}
                    />
                  </div>
                  <div className="PropertyAgencyFormGroup">
                    {dataFormResult?.errors?.city && (
                      <span className="PropertyAgencyInputError">
                        {dataFormResult.errors.city}
                      </span>
                    )}
                    <input
                      className="PropertyAgencyInput"
                      type="text"
                      name="city"
                      placeholder="Cidade"
                      onChange={handleSetDataForm}
                      ref={inputCity}
                    />
                  </div>
                  <div className="PropertyAgencyFormGroup">
                    {dataFormResult?.errors?.neighborhood && (
                      <span className="PropertyAgencyInputError">
                        {dataFormResult.errors.neighborhood}
                      </span>
                    )}
                    <input
                      className="PropertyAgencyInput"
                      type="text"
                      name="neighborhood"
                      placeholder="Bairro"
                      onChange={handleSetDataForm}
                      ref={inputNeighborhood}
                    />
                  </div>
                  <div className="PropertyAgencyFormGroup">
                    {dataFormResult?.errors?.address && (
                      <span className="PropertyAgencyInputError">
                        {dataFormResult.errors.address}
                      </span>
                    )}
                    <input
                      className="PropertyAgencyInput"
                      type="text"
                      name="address"
                      placeholder="Endereço"
                      onChange={handleSetDataForm}
                      ref={inputAddress}
                    />
                  </div>
                  <div className="PropertyAgencyFormGroup">
                    {dataFormResult?.errors?.tipo && (
                      <span className="PropertyAgencyInputError">
                        {dataFormResult.errors.tipo}
                      </span>
                    )}
                    <input
                      className="PropertyAgencyInput"
                      type="text"
                      name="tipo"
                      placeholder="Tipo do imóvel: Casa, Apartamento, ..."
                      onChange={handleSetDataForm}
                      ref={inputTipo}
                    />
                  </div>
                  <div className="PropertyAgencyFormGroup">
                    <input
                      className="PropertyAgencyInput"
                      type="text"
                      name="dormitorio"
                      placeholder="Dormitórios, ex: '3, 1 com suíte'"
                      onChange={handleSetDataForm}
                      ref={inputDormitorio}
                    />
                  </div>
                  <div className="PropertyAgencyFormGroup">
                    <input
                      className="PropertyAgencyInput"
                      type="text"
                      name="garagem"
                      placeholder="Garagem pra quantos carros?"
                      onChange={handleSetDataForm}
                      ref={inputGaragem}
                    />
                  </div>

                  <div className="PropertyAgencyFormSwitchContainer">
                    <div className="PropertyAgencyFormSwitchGroup">
                      <span className="PropertyAgencyFormSwitchLabel">
                        Lavanderia
                      </span>
                      <Switch
                        onChange={(e) => handleSwitchChange(e, "lavanderia")}
                        checked={
                          dataForm?.lavanderia != undefined
                            ? dataForm?.lavanderia
                            : false
                        }
                      />
                    </div>
                    <div className="PropertyAgencyFormSwitchGroup">
                      <span className="PropertyAgencyFormSwitchLabel">
                        Alarme
                      </span>
                      <Switch
                        onChange={(e) => handleSwitchChange(e, "alarme")}
                        checked={
                          dataForm?.alarme != undefined
                            ? dataForm?.alarme
                            : false
                        }
                      />
                    </div>
                    <div className="PropertyAgencyFormSwitchGroup">
                      <span className="PropertyAgencyFormSwitchLabel">
                        Elevador
                      </span>
                      <Switch
                        onChange={(e) => handleSwitchChange(e, "elevador")}
                        checked={
                          dataForm?.elevador != undefined
                            ? dataForm?.elevador
                            : false
                        }
                      />
                    </div>
                    <div className="PropertyAgencyFormSwitchGroup">
                      <span className="PropertyAgencyFormSwitchLabel">
                        Portão Eletrônico
                      </span>
                      <Switch
                        onChange={(e) =>
                          handleSwitchChange(e, "portaoEletronico")
                        }
                        checked={
                          dataForm?.portaoEletronico != undefined
                            ? dataForm?.portaoEletronico
                            : false
                        }
                      />
                    </div>
                    <div className="PropertyAgencyFormSwitchGroup">
                      <span className="PropertyAgencyFormSwitchLabel">
                        Poço Artesiano
                      </span>
                      <Switch
                        onChange={(e) => handleSwitchChange(e, "pocoArtesiano")}
                        checked={
                          dataForm?.pocoArtesiano != undefined
                            ? dataForm?.pocoArtesiano
                            : false
                        }
                      />
                    </div>
                    <div className="PropertyAgencyFormSwitchGroup">
                      <span className="PropertyAgencyFormSwitchLabel">
                        Cerca Elétrica
                      </span>
                      <Switch
                        onChange={(e) => handleSwitchChange(e, "cercaEletrica")}
                        checked={
                          dataForm?.cercaEletrica != undefined
                            ? dataForm?.cercaEletrica
                            : false
                        }
                      />
                    </div>
                    <div className="PropertyAgencyFormSwitchGroup">
                      <span className="PropertyAgencyFormSwitchLabel">
                        Câmera de Vídeo
                      </span>
                      <Switch
                        onChange={(e) => handleSwitchChange(e, "cameraDeVideo")}
                        checked={
                          dataForm?.cameraDeVideo != undefined
                            ? dataForm?.cameraDeVideo
                            : false
                        }
                      />
                    </div>
                  </div>

                  <div className="PropertyAgencyFormGroup">
                    <span className="PropertyAgencyLabel">
                      Se você tem algo que acha relevante nos diga:
                    </span>
                    <textarea
                      className="ContactEmailText"
                      name="obs"
                      onChange={handleSetDataForm}
                      defaultValue={dataForm?.obj}
                      ref={inputObs}
                    ></textarea>
                  </div>

                  <div className="PropertyAgencyLabelContainer">
                    <p className="PropertyAgencyLabel">
                      Trabalhamos com fotos de qualidade pra dar o devido valor
                      ao seu imóvel. Então vamos falar sobre as fotos quando
                      entrar em contato!
                    </p>
                  </div>

                  {messageSuccess && (
                    <Alert
                      type="Success"
                      text="Agradecemos sua confiança. Em breve entraremos em contato!"
                    />
                  )}

                  <div className="PropertyAgencyFormGroup">
                    {dataFormResult?.errors && (
                      <span className="PropertyAgencyInputError">
                        Verifique os campos
                      </span>
                    )}
                    {recaptchaToken && (
                      <button
                        type="submit"
                        className="PropertyAgencyButtonSend"
                        // onClick={handlePostProspect}
                      >
                        Cadastrar
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </GoogleReCaptchaProvider>
          <div className="PropertyAgencyBackdrop"></div>
        </>
      )}
    </>
  );
};

export default PropertiesAgency;
