//////////////
// Application
//////////////

/**
 * Config.
 */
import config from "../../config";

/**
 * Services Http.
 */
import { GET } from "../http";

/**
 * Helpers.
 */
import * as Helper from "../../helpers";

/**
 * Get cities.
 */
export const serviceGetCities = async ({ queryString }) => {
  let data = {};

  try {
    const url = `${config.baseUrlApi}/pub/${config.imob.domain}/cities${
      queryString ? "?" + queryString : ""
    }`;
    data = await GET({ url });
    return data;
  } catch (e) {
    console.log(e);
  }

  return data;
};
