///////
// RJS
//////
import React, { useState } from "react";

/**
 * Router DOM.
 */
import { Switch, Route } from "react-router-dom";

/**
 * Helmet SEO.
 */
// import { Helmet } from "react-helmet";

///////////////
// Application.
///////////////

/**
 * Config.
 */
import config from "../config";

/**
 * Components.
 */
import Header from "../components/header";
import Filter from "../components/filter";
import Footer from "../components/footer";
import Analytics from "../components/analytics";
import PropertiesAgency from "../components/propertiesAgency";
import CockiesAlert from '../components/CockiesAlert'

/**
 * Pages.
 */
// import ComingSoon from "./pages/comingSoon";
import Home from "../pages/home";
import PropertiesList from "../pages/propertiesList";
import PropertyDetail from "../pages/propertyDetail";
import Financing from "../pages/financing";
import About from "../pages/About";
// import ComingSoon from "../pages/comingSoon";
import NotFound from "../pages/notFound";

const Routes = () => {
  const [dataSearch, setDataSearch] = useState({});

  return (
    <Switch>
      {/**
       * Root page.
       */}
      <Route path={config.navigation.root.link} exact>
        {/* <ComingSoon /> */}
        <Header slide />
        <PropertiesAgency />
        <Filter dataSearch={dataSearch} handleSetDataSearch={setDataSearch} />
        <Home />
        <Footer />
        <Analytics />
        <CockiesAlert />
      </Route>

      {/**
       * Home page.
       
      <Route path={config.navigation.home.link} exact>
        <Header slide />
        <Filter dataSearch={dataSearch} handleSetDataSearch={setDataSearch} />
        <Home />
        <Footer />
        <CockiesAlert />
      </Route>
      */}

      {/**
       * Property Detail.
       */}
      <Route path={`${config.navigation.property.link}/:code`} exact>
        {/* <Helmet>
          <title>Imovel Detail</title>
          <meta name="description" content="Detalhes do imovel" />
        </Helmet> */}
        <Header />
        <PropertyDetail />
        <Footer />
        <CockiesAlert />
      </Route>

      {/**
       * Properties List.
       */}
      <Route path={`${config.navigation.properties.link}`} exact>
        {/* <Helmet>
          <title>Imoveis List</title>
          <meta name="description" content="Listagem de imoveis" />
        </Helmet> */}
        <Header />
        <PropertiesList />
        <Footer />
        <Analytics />
        <CockiesAlert />
      </Route>

      {/**
       * Financing.
       */}
      <Route path={`${config.navigation.financing.link}`} exact>
        {/* <Helmet>
          <title>Imoveis List</title>
          <meta name="description" content="Listagem de imoveis" />
        </Helmet> */}
        <Header />
        <Financing />
        <Footer />
        <Analytics />
        <CockiesAlert />
      </Route>

      {/**
       * About.
       */}
      <Route path={`${config.navigation.about.link}`} exact>
        <Header />
        <About />
        <Footer />
        <Analytics />
        <CockiesAlert />
      </Route>

      {/**
       * Not Found.
       */}
      <Route path="*">
        <Header />
        <NotFound />
        <Footer />
        <Analytics />
        <CockiesAlert />
      </Route>
    </Switch>
  );
};

export default Routes;
