///////
// RJS
//////
import React, { useEffect, useState, useRef } from "react";

/**
 * Router DOM.
 */
import { useParams, useHistory } from "react-router-dom";

/**
 * Video React.
 */
import { Player } from "video-react";

//////////////
// Application
//////////////

/**
 * Libs.
 */
 import {
  FaAngleLeft,
  FaAngleRight,
  FaTimes
} from "react-icons/fa";

/**
 * Config.
 */
import config from "../../config";

/**
 * Leaflet Maps.
 * Example: https://github.com/PaulLeCam/react-leaflet/blob/master/example/components/simple.js
 */
import {
  Map,
  TileLayer,
  // Marker,
  Circle,
  // CircleMarker,
  // Popup,
} from "react-leaflet";

/**
 * Helpers.
 */
import * as Helper from "../../helpers";
import * as HOH from "../../helpers/highOrderHelper";

/**
 * Services.
 */
import { serviceGetPropertyDetails } from "../../services/api/properties";
import { serviceGetProspect } from "../../services/api/prospects";

/**
 * Components.
 */
import ContactEmail from "../../components/contact/email";
// import ContactSocialMedia from "../../components/contact/socialMedia";
// import Lightbox from "../../components/lightbox";
import CaptureInfos from "../../components/captureInfos";
// import ContactWhats from "../../components/contact/contactWhats";
import Exclusividade from "../../components/exclusividade";
import Analytics from "../../components/analytics";

/**
 * Assets.
 */
//  import iconLoading from '../../assets/icon-s-loading.png';

/**
 * Styles.
 */
import "./styles.css";

/**
 * Interval data.
 */
 const intervalBannerSlide = {
  index: 0,
};

const PropertyDetail = () => {
  /**
   * Receive params URL react routerd dom;
   */
  const { code } = useParams();

  /**
   * Redirect with History Router DOM.
   * Example: history.push('/home');
   */
  const history = useHistory();

  /**
   * Get property details.
   */
  const [property, setProperty] = useState({});

  /**
   * Refs.
  */
  const slideThumbsContainer = useRef(null);
  const slideImageActive = useRef(null);

  const getPropertyDetails = async () => {
    Helper.consoleBeggin();
    console.log("DEBUGROUTE code:", code);
    const result = await serviceGetPropertyDetails({ code });
    console.log("PropertyDetail getPropertyDetails, result:", result);
    if (result?.data?.property) {
      console.log("PropertyDetail getPropertyDetails, result:", result);
      // result.data.property.areaEsquerda = 123;
      // result.data.property.areaDireita = 123;
      // result.data.property.areaFrente = 123;
      // result.data.property.areaFundos = 123;
      setProperty(result?.data?.property);
    } else {
      console.log("PropertyDetail REDIRECT 404.");
      history.push(config.navigation.notFound.link);
    }
    Helper.consoleEnd();
  };

  useEffect(() => {
    getPropertyDetails();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * Slide de fotos 
   */
  const [dataBannersActive, setDataBannersActive] = useState(null);

  // const onLoadImageActive = () => {
  //   try {
  //     console.log(`LOADING IMG...`);
  //     slideImageActive.current.src = dataBannersActive?.normal;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const handleDataBannerActive = (banner) => {
    try {
      console.log(`SELECT IMAGE AND OPEN LIGHTBOX`, banner);
      // if (intervalBannerSlide.id) clearInterval(intervalBannerSlide.id);
      setDataBannersActive(banner);
    } catch (error) {
      console.log(error);
    }
  };

  let [slideScroll, setSlideScroll] = useState(0);

  const handlePrevScroll = () => {
    try {
      const newValue = slideScroll + -375
      slideThumbsContainer.current.scroll({
        top: 0,
        left: newValue,
        behavior: 'smooth'
      });
      setSlideScroll(newValue);
    } catch (error) {
      console.log(error);
    }
  }

  const handleNextScroll = () => {
    try {
      const newValue = slideScroll + 375
      slideThumbsContainer.current.scroll({
        top: 0,
        left: newValue,
        behavior: 'smooth'
      });
      setSlideScroll(newValue);
    } catch (error) {
      console.log(error);
    }
  }

  const handlePrevSlide = () => {
    try {
      let dataBannersIndex = intervalBannerSlide.index - 1;
      if (intervalBannerSlide.index === 0) dataBannersIndex = photos.length - 1;
      intervalBannerSlide.index = dataBannersIndex;
      setDataBannersActive(photos[dataBannersIndex]);
    } catch (error) {
      console.log(error);
    }
  }

  const handleNextSlide = () => {
    try {
      let dataBannersIndex = intervalBannerSlide.index + 1;
      if (intervalBannerSlide.index === photos.length-1) dataBannersIndex = 0;
      intervalBannerSlide.index = dataBannersIndex;
      setDataBannersActive(photos[dataBannersIndex]);
    } catch (error) {
      console.log(error);
    }
  }

  const handleLightboxClose = () => {
    try {
      setDataBannersActive(null);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Get/check infos previded by the visitor to basically release photos.
   */

  /* Set photos of property. */
  const [photos, setPhotos] = useState(null);

  useEffect(() => {
    checkSetInfosVisitor();
  }, []);

  const [dataProspect, setDataProspect] = useState(null);

  const checkSetInfosVisitor = async () => {
    try {
      let prospect = JSON.parse(
        localStorage.getItem(`@${config?.imob?.domain}`)
      );

      const email = prospect?.email;
      if (email) {
        const result = await serviceGetProspect({ email });
        console.log("checkSetprospect result:", result);
        prospect = result?.data;
      }

      console.log("checkSetprospect prospect:", prospect);
      if (prospect?.email) setDataProspect({ ...prospect });
      console.log("checkSetprospect dataProspect:", dataProspect);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    /**
     * Foi um pedido do cliente limitar o número de fotos visíveis caso o visitante não tenha se cadastrado
     * Mas o pedido foi revogado depois de algum tempo...
    */
    console.log("checkSetprospect dataProspect:", dataProspect);
    /*if (dataProspect && dataProspect?.status !== "disapproved") {
      if (property?.photos?.data?.length) {
        setPhotos(property?.photos?.data);
      }
    } else {
      if (property?.photos?.data?.length) {
        setPhotos(property.photos.data.slice(0, 4));
      }
    }*/
    if (property?.photos?.data?.length) {
      console.log("Photos property.photos.data:", property.photos.data);
      // setDataBannersActive(property?.photos?.data[0]);
      if (property?.video?.data?.url) {
        property.photos.data.unshift({
          id: 'video',
          poster: property.photos.data.thumb,
          src: property.video.data.url,
          property_id: 852,
        });
      }
      setPhotos(property?.photos?.data);
    }
  }, [dataProspect, property]);

  useEffect(() => {
    console.log("checkSetprospect photos:", photos);
  }, [photos]);

  const handleCallbackSaveCaptureInfos = () => {
    checkSetInfosVisitor();
  };

  /**
   * Hide/Show infos.
   */
  const showInfosListDetalhes = () => {
    return (
      !!Helper.isValidInt(property?.dormitorio) ||
      !!Helper.isValidInt(property?.garagem)
    );
  };

  /**
   * Hide/Show área infos.
   */
  const showAreaInfosListDetalhes = () => {
    return (
      !!Helper.isValidInt(property?.areaTotal) ||
      !!Helper.isValidInt(property?.areaConstruida) ||
      !!Helper.isValidInt(property?.areaFrente) ||
      !!Helper.isValidInt(property?.areaFundos) ||
      !!Helper.isValidInt(property?.areaDireita) ||
      !!Helper.isValidInt(property?.areaEsquerda)
    );
  };

  /**
   * Lightbox images.
   */
  // const [openLightbox, setOpenLightbox] = useState(null);

  // const handleOpenLightbox = (photo) => setOpenLightbox(photo);
  // const handleCloseLightbox = () => setOpenLightbox(null);

  /**
   * Leaflet Map.
   * Example, Arezzo location: -29.6732856,-51.0436304
   */
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((location) => {
      console.log("geolocation.getCurrentPosition location:", location);
    });
  }, []);

  /**
   *
   * DINAMIC INPUT CHANGES...
   *
   */
  // const handleChangeInput = (e) => {
  //   const { name, value } = e.target;
  //   const dataDin = {};
  //   dataDin[name] = value;
  //   console.log("handleChangeInput, dataDin:", dataDin);
  // };

  /**
   *
   * Spread Array values.
   *
   */
  // const spreadArray = (newValue) => {
  //   const arr = [0, 2, 5];
  //   const newDataSpread = [...arr, newValue];
  //   console.log("spreadArray, newDataSpread:", newDataSpread);
  // };

  /**
   *
   * Resolve rotate images class.
   *
   */
  const resolveRotate = (photo) => {
    try {
      if (photo.rotate) {
        if (!isNaN(photo.rotate)) {
          switch (photo.rotate) {
            case 90:
              return "PropertyDetailPhoto--rotate90";
            case 180:
              return "PropertyDetailPhoto--rotate180";
            case 270:
              return "PropertyDetailPhoto--rotate270";
            case 360:
              return "PropertyDetailPhoto--rotate360";
            default:
              return "";
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    return "";
  };

  console.log('dataBannersActive:', dataBannersActive);

  return (
    <>
      {/**
       *   Fragment Container: <></>
       */}
      {/* {openLightbox && (
        <Lightbox
          data={property?.photos?.data}
          image={openLightbox}
          handleCloseLightbox={handleCloseLightbox}
        />
      )} */}

      <div className="PropertyDetailInfosContainer">
        <div className="PropertyDetailInfosContent">
          <h1 className="PropertyDetailInfosTitle">
            {HOH.getTitleInfoProperty(property)}
          </h1>
          <p className="PropertyDetailInfosDesc">{property?.descGeral}</p>
        </div>
      </div>

      {/**
       * Slide 
      */}
      <div className="SlidePropertyDetail PriorityOneContainer">
        <div className="SlideContainer">
          <div
            ref={slideThumbsContainer}
            className={`SlideThumbsContainer 
            ${photos?.length >= 3 ? " SlideThumbsContainer3 " : ""} 
            ${photos?.length >= 6 ? " SlideThumbsContainer6 " : ""}
            ${photos?.length >= 9 ? " SlideThumbsContainer9 " : ""}
            ${photos?.length >= 12 ? " SlideThumbsContainer12 " : ""}`}
          >
            {!!photos?.length &&
              photos.map((banner, i) => (
                <div
                  key={String(i)}
                  className={`SlideThumbPropertyDetail ${
                    banner?.id === dataBannersActive?.id ? "active" : ""
                  }`}
                  onClick={() => handleDataBannerActive(banner)}
                >
                  {banner.src ? (
                    <Player
                      playsInline
                      fluid={true}
                      poster={banner.poster}
                      src={banner.src}
                    />
                  ) : (
                    <img
                      className="ThumbImage"
                      src={banner?.thumb}
                      alt={banner?.titulo}
                    />
                  )}
                </div>
              ))}
          </div>
          <FaAngleLeft className="SlideContainer--btn-prev" onClick={handlePrevScroll} />
          <FaAngleRight className="SlideContainer--btn-next" onClick={handleNextScroll} />
        </div>
      </div>

      {/**
       * Lightbox
      */}
      {dataBannersActive && (
        <>
          <div className="LightboxDropbox"></div>
          <div className="LightboxContainer">
            <div className="LightboxContent">
              <div className="SlideContainer">
                {dataBannersActive?.src &&
                  <Player
                    playsInline
                    fluid={true}
                    poster={dataBannersActive.posrter}
                    src={dataBannersActive.src}
                />}
                {dataBannersActive?.normal && <img ref={slideImageActive} className="SlideImageActive" src={dataBannersActive?.normal} />}
    
                <div
                  className={`SlideThumbsContainer 
                  ${photos?.length >= 3 ? " SlideThumbsContainer3 " : ""} 
                  ${photos?.length >= 6 ? " SlideThumbsContainer6 " : ""}
                  ${photos?.length >= 9 ? " SlideThumbsContainer9 " : ""}
                  ${photos?.length >= 12 ? " SlideThumbsContainer12 " : ""}`}
                >
                  {!!photos?.length &&
                    photos.map((banner, i) => (
                      <div
                        key={String(i)}
                        className={`SlideThumb ${
                          banner?.id === dataBannersActive?.id ? "active" : ""
                        }`}
                        onClick={() => handleDataBannerActive(banner)}
                      >
                        {banner?.src ? (
                          <Player
                            playsInline
                            fluid={true}
                            poster={banner.poster}
                            src={banner.src}
                          />
                        ) : (
                          <img
                            className="ThumbImage"
                            src={banner?.thumb}
                            alt={banner?.titulo}
                          />
                        )}
                      </div>
                    ))}
                </div>
                <FaTimes className="SlideContainer--btn-close" onClick={handleLightboxClose} /> 
                <FaAngleLeft className="SlideContainer--btn-prev" onClick={handlePrevSlide} />
                <FaAngleRight className="SlideContainer--btn-next" onClick={handleNextSlide} />
              </div>
            </div>
          </div>
        </>
      )}

      {/* {property?.video && (
        <div className="PropertyDetailInfosContainer PlayerVideoContainer">
          <div className="PropertyDetailInfosContent">
            <Player
              playsInline
              fluid={false}
              // height={400}
              poster={property?.photo?.data?.thumb}
              src={property?.video?.data?.url}
            />
          </div>
        </div>
      )} */}

      {/* <div className="PropertyDetailInfosContainer">
        <div className="PropertyDetailInfosContent">
          <ContactSocialMedia />
        </div>
      </div> */}

      {/* <ContactWhats
        text={`Olá, interessante este imóvel: ${window.location} - gostaria de saber...`}
      /> */}

      <div className="PropertyDetailInfosContainer InfosList">
        <div className="PropertyDetailInfosContent">
          <ul className="InfosList NoBorderLastItem">
            <span className="InfoFly CodInfo">
              {HOH.getCodeInfoProperty(property)}
            </span>

            {property?.hasExclusividade && <Exclusividade />}
            <li className="InfosListRow RowBold">{property?.tipo}</li>
            <li className="InfosListRow RowBold">
              {HOH.getPriceInfoProperty(property, true)}
            </li>
            {property?.categoria && (
              <li className="InfosListRow RowBold">{property?.categoria}</li>
            )}
          </ul>

          {showInfosListDetalhes() && (
            <>
              <ul className="InfosList InfosListTitle">
                <li className="InfosListRow RowTitle">Detalhes</li>
              </ul>
              <ul className="InfosList InfosListHorizontal">
                {!!Helper.isValidInt(property?.dormitorio) && (
                  <>
                    <li className="InfosListRow RowTitleIcon RowCenter">
                      <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M9 33.9H12.2V30.75H35.8V33.9H39V25.1Q39 24.2 38.325 23.175Q37.65 22.15 36.6 21.6V18.2Q36.6 16.6 35.675 15.675Q34.75 14.75 33.15 14.75H26.35Q25.55 14.75 24.95 15Q24.35 15.25 23.95 15.75Q23.55 15.25 22.95 15Q22.35 14.75 21.55 14.75H14.85Q13.25 14.75 12.325 15.675Q11.4 16.6 11.4 18.2V21.6Q10.35 22.15 9.675 23.175Q9 24.2 9 25.1ZM25.25 21.65V17.25Q25.25 17.25 25.25 17.25Q25.25 17.25 25.25 17.25H34.1Q34.1 17.25 34.1 17.25Q34.1 17.25 34.1 17.25V21.65ZM13.9 21.65V17.25Q13.9 17.25 13.9 17.25Q13.9 17.25 13.9 17.25H22.75Q22.75 17.25 22.75 17.25Q22.75 17.25 22.75 17.25V21.65ZM12.2 28.25V26.15Q12.2 25.2 12.725 24.675Q13.25 24.15 14.2 24.15H33.8Q34.75 24.15 35.275 24.675Q35.8 25.2 35.8 26.15V28.25ZM7 44Q5.8 44 4.9 43.1Q4 42.2 4 41V7Q4 5.8 4.9 4.9Q5.8 4 7 4H41Q42.2 4 43.1 4.9Q44 5.8 44 7V41Q44 42.2 43.1 43.1Q42.2 44 41 44ZM7 41H41Q41 41 41 41Q41 41 41 41V7Q41 7 41 7Q41 7 41 7H7Q7 7 7 7Q7 7 7 7V41Q7 41 7 41Q7 41 7 41ZM7 41Q7 41 7 41Q7 41 7 41V7Q7 7 7 7Q7 7 7 7Q7 7 7 7Q7 7 7 7V41Q7 41 7 41Q7 41 7 41Z"/></svg>
                    </li>
                    <li className="InfosListRow RowCenter RowBold">
                      {property?.dormitorio} Dormitório(s)
                    </li>
                  </>
                )}
                {!!Helper.isValidInt(property?.garagem) && (
                  <>
                    <li className="InfosListRow RowTitleIcon RowCenter">
                      <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M10 37.8V40.5Q10 41.15 9.575 41.575Q9.15 42 8.5 42H7.5Q6.85 42 6.425 41.575Q6 41.15 6 40.5V24.3L10.25 11.5Q10.5 10.8 11.075 10.4Q11.65 10 12.4 10H35.6Q36.35 10 36.925 10.4Q37.5 10.8 37.75 11.5L42 24.3V40.5Q42 41.15 41.575 41.575Q41.15 42 40.5 42H39.45Q38.8 42 38.4 41.575Q38 41.15 38 40.5V37.8ZM10.15 21.3H37.85L35.1 13H12.9ZM9 24.3V34.8ZM14.3 32.3Q15.45 32.3 16.225 31.5Q17 30.7 17 29.6Q17 28.45 16.225 27.625Q15.45 26.8 14.3 26.8Q13.15 26.8 12.325 27.625Q11.5 28.45 11.5 29.6Q11.5 30.75 12.325 31.525Q13.15 32.3 14.3 32.3ZM33.75 32.3Q34.9 32.3 35.725 31.5Q36.55 30.7 36.55 29.6Q36.55 28.45 35.725 27.625Q34.9 26.8 33.75 26.8Q32.6 26.8 31.825 27.625Q31.05 28.45 31.05 29.6Q31.05 30.75 31.85 31.525Q32.65 32.3 33.75 32.3ZM9 34.8H39V24.3H9Z"/></svg>
                    </li>
                    <li className="InfosListRow RowCenter RowBold">
                      {property?.garagem} Vagas(s)
                    </li>
                  </>
                )}
              </ul>
            </>
          )}

          {showAreaInfosListDetalhes() && (
            <>
              <ul className="InfosList InfosListArea InfosListTitle">
                <li className="InfosListRow RowTitle">Espaço</li>
              </ul>
              <ul className="InfosList InfosListArea InfosListHorizontal">
                {property.areaTotal && (
                  <>
                    <li className="InfosListRow RowTitleIcon RowCenter">
                      <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M36.3 42H10Q8.2 42 7.1 40.9Q6 39.8 6 38V11.05Q6 8.8 7.6 8.175Q9.2 7.55 10.8 9.15L13.05 11.4L10.95 13.5L12.3 14.85L14.4 12.75L20.25 18.6L18.15 20.7L19.5 22.05L21.6 19.95L27.5 25.85L25.4 27.95L26.75 29.3L28.85 27.2L34.7 33.05L32.6 35.15L33.95 36.5L36.05 34.4L38.3 36.65Q40.3 38.65 39.625 40.325Q38.95 42 36.3 42ZM10 14.85V38Q10 38 10 38Q10 38 10 38H33.15Z"/></svg>
                    </li>
                    <li className="InfosListRow RowCenter RowBold AreasInfos">
                      {HOH.getAreaInfoProperty(property, "areaTotal")}{" "}
                      <small>(total)</small>
                    </li>
                  </>
                )}
                {property.areaConstruida && (
                  <>
                    <li className="InfosListRow RowTitleIcon RowCenter">
                      <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M36.3 42H10Q8.2 42 7.1 40.9Q6 39.8 6 38V11.05Q6 8.8 7.6 8.175Q9.2 7.55 10.8 9.15L13.05 11.4L10.95 13.5L12.3 14.85L14.4 12.75L20.25 18.6L18.15 20.7L19.5 22.05L21.6 19.95L27.5 25.85L25.4 27.95L26.75 29.3L28.85 27.2L34.7 33.05L32.6 35.15L33.95 36.5L36.05 34.4L38.3 36.65Q40.3 38.65 39.625 40.325Q38.95 42 36.3 42ZM10 14.85V38Q10 38 10 38Q10 38 10 38H33.15Z"/></svg>
                    </li>
                    <li className="InfosListRow RowCenter RowBold AreasInfos">
                      {HOH.getAreaInfoProperty(property, "areaConstruida")}{" "}
                      <small>(construído)</small>
                    </li>
                  </>
                )}
                {property.areaFrente && (
                  <>
                    <li className="InfosListRow RowTitleIcon RowCenter">
                      <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M36.3 42H10Q8.2 42 7.1 40.9Q6 39.8 6 38V11.05Q6 8.8 7.6 8.175Q9.2 7.55 10.8 9.15L13.05 11.4L10.95 13.5L12.3 14.85L14.4 12.75L20.25 18.6L18.15 20.7L19.5 22.05L21.6 19.95L27.5 25.85L25.4 27.95L26.75 29.3L28.85 27.2L34.7 33.05L32.6 35.15L33.95 36.5L36.05 34.4L38.3 36.65Q40.3 38.65 39.625 40.325Q38.95 42 36.3 42ZM10 14.85V38Q10 38 10 38Q10 38 10 38H33.15Z"/></svg>
                    </li>
                    <li className="InfosListRow RowCenter RowBold AreasInfos">
                      {HOH.getAreaInfoProperty(property, "areaFrente")}{" "}
                      <small>(frente)</small>
                    </li>
                  </>
                )}
                {property.areaFundos && (
                  <>
                    <li className="InfosListRow RowTitleIcon RowCenter">
                      <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M36.3 42H10Q8.2 42 7.1 40.9Q6 39.8 6 38V11.05Q6 8.8 7.6 8.175Q9.2 7.55 10.8 9.15L13.05 11.4L10.95 13.5L12.3 14.85L14.4 12.75L20.25 18.6L18.15 20.7L19.5 22.05L21.6 19.95L27.5 25.85L25.4 27.95L26.75 29.3L28.85 27.2L34.7 33.05L32.6 35.15L33.95 36.5L36.05 34.4L38.3 36.65Q40.3 38.65 39.625 40.325Q38.95 42 36.3 42ZM10 14.85V38Q10 38 10 38Q10 38 10 38H33.15Z"/></svg>
                    </li>
                    <li className="InfosListRow RowCenter RowBold AreasInfos">
                      {HOH.getAreaInfoProperty(property, "areaFundos")}{" "}
                      <small>(fundos)</small>
                    </li>
                  </>
                )}
                {property.areaEsquerda && (
                  <>
                    <li className="InfosListRow RowTitleIcon RowCenter">
                      <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M36.3 42H10Q8.2 42 7.1 40.9Q6 39.8 6 38V11.05Q6 8.8 7.6 8.175Q9.2 7.55 10.8 9.15L13.05 11.4L10.95 13.5L12.3 14.85L14.4 12.75L20.25 18.6L18.15 20.7L19.5 22.05L21.6 19.95L27.5 25.85L25.4 27.95L26.75 29.3L28.85 27.2L34.7 33.05L32.6 35.15L33.95 36.5L36.05 34.4L38.3 36.65Q40.3 38.65 39.625 40.325Q38.95 42 36.3 42ZM10 14.85V38Q10 38 10 38Q10 38 10 38H33.15Z"/></svg>
                    </li>
                    <li className="InfosListRow RowCenter RowBold AreasInfos">
                      {HOH.getAreaInfoProperty(property, "areaEsquerda")}{" "}
                      <small>(esquerda)</small>
                    </li>
                  </>
                )}
                {property.areaDireita && (
                  <>
                    <li className="InfosListRow RowTitleIcon RowCenter">
                      <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M36.3 42H10Q8.2 42 7.1 40.9Q6 39.8 6 38V11.05Q6 8.8 7.6 8.175Q9.2 7.55 10.8 9.15L13.05 11.4L10.95 13.5L12.3 14.85L14.4 12.75L20.25 18.6L18.15 20.7L19.5 22.05L21.6 19.95L27.5 25.85L25.4 27.95L26.75 29.3L28.85 27.2L34.7 33.05L32.6 35.15L33.95 36.5L36.05 34.4L38.3 36.65Q40.3 38.65 39.625 40.325Q38.95 42 36.3 42ZM10 14.85V38Q10 38 10 38Q10 38 10 38H33.15Z"/></svg>
                    </li>
                    <li className="InfosListRow RowCenter RowBold AreasInfos">
                      {HOH.getAreaInfoProperty(property, "areaDireita")}{" "}
                      <small>(direita)</small>
                    </li>
                  </>
                )}
              </ul>
            </>
          )}

          <ul className="InfosList InfosListTitle">
            <li className="InfosListRow RowTitle">Localização</li>
          </ul>
          <ul className="InfosList ListMiddleFixMargin">
            <li className="InfosListRow RowBold">
              {property?.neighborhood?.data?.nome}
            </li>
            <li className="InfosListRow RowBold">
              {property?.neighborhood?.data?.city?.data?.long_desc}
            </li>
          </ul>

          {property.sitePublicarMapa && property.latitude && property.longitude && (
            <div className="MapContainer">
              <Map
                center={[property.latitude, property.longitude]}
                zoom={property.zoom}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Circle
                  center={[property.latitude, property.longitude]}
                  fillColor="#829FD9"
                  radius={500}
                />
              </Map>
            </div>
          )}
        </div>
      </div>

      <div className="PropertyDetail">
        <div className="PropertyDetailContent">
          {!dataProspect?.email && (
            <CaptureInfos
              dataProspect={dataProspect}
              property={property}
              handlCallbackSave={handleCallbackSaveCaptureInfos}
            />
          )}

          {/* <div className="PropertyDetailPhotosContainer">
            {!!photos?.length &&
              photos.map((photo, i) => (
                <div className="PropertyDetailPhotoContainer" key={String(i)}>
                  <div
                    className={`PropertyDetailPhotoContent ${resolveRotate(
                      photo
                    )}`}
                  >
                    <img
                      className={`PropertyDetailPhoto`}
                      src={photo.thumb}
                      alt={`Foto ${i + 1} Imóvel ${property.code}`}
                      onClick={() => handleOpenLightbox(photo)}
                    />
                  </div>
                </div>
              ))}
          </div> */}
        </div>
      </div>

      <div className="PropertyDetailInfosContainer">
        <div className="PropertyDetailInfosContent">
          <ContactEmail property={property} />
        </div>
      </div>

      {property?.id && <Analytics property={property} />}
    </>
  );
};

export default PropertyDetail;
