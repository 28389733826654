export const GET = async ({ url }) => {
  let response = {};

  try {
    const params = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    response = await fetch(url, params);
    return await response?.json();
  } catch (e) {
    console.log(e);
  }

  return response;
};

export const POST = async ({ url, dataForm }) => {
  let response = {};

  try {
    const params = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataForm),
    };

    response = await fetch(url, params);
    return await response?.json();
  } catch (e) {
    console.log(e);
  }

  return response;
};
