///////
// RJS
///////
import React from "react";
import "./App.css";

/**
 * Router DOM.
 */
import { BrowserRouter } from "react-router-dom";

//////////////
// Application
//////////////

/**
 * Routes.
 */
import Routes from "./routes";

function App() {
  return (
    <BrowserRouter basename="/site">
      <Routes />
    </BrowserRouter>
  );
}

export default App;
