/**
 * Config.
 */
import config from "../config";

/**
 * Fist Level helpers.
 */
import * as Helper from "./index";

/**
 * Check URL navigation.
 */
export const isUrl = (navUrl) => {
  try {
    Helper.consoleBeggin("DEBUGFILTRO");
    console.log("useEffect() isUrl(), navUrl:", navUrl);

    const { pathname } = window.location;
    console.log("useEffect() isUrl(), pathname:", pathname);

    const result = Object.entries(config.navigation).find(
      (cn) => cn[1].link === navUrl
    );

    console.log("useEffect() isUrl(), result:", result);

    /**
     * If find url in config.navigation return tru or false
     */
    Helper.consoleEnd("DEBUGFILTRO");

    if (result) return pathname.toString().indexOf(result[1].link) > -1;

    /**
     * If not find url in config.navigation return undefined.
     */
    return undefined;
  } catch (e) {
    console.log(e);
  }

  /**
   * If trow return undefined.
   */
  return undefined;
};

/**
 * Return URL to property datail.
 *
 * @return String; Format: 'imovel/:code/:type/:city'
 */
export const getUrlProperty = (property) => {
  try {
    let url = `${config.navigation.property.link}/${property?.code}`;
    return url;
  } catch (e) {
    console.log(e);
  }
  return "";
};

/**
 *  Return área with character m²
 */
export const getAreaInfoProperty = (property, flag) => {
  const labelDefault = `--`;

  try {
    if (property[flag]) return `${property[flag]}m²`;
  } catch (e) {
    console.log(e);
  }

  return labelDefault;
};

/**
 * Return code info of property list.
 */
export const getCodeInfoProperty = (property) => {
  const labelDefault = `Cód --`;

  try {
    return `Cód ${
      property?.codePretty ? property?.codePretty : property?.code
    }`;
  } catch (e) {
    console.log(e);
  }

  return labelDefault;
};

/**
 * Return nomeImovel or a title concat.
 */
export const getTitleInfoProperty = (property) => {
  const labelDefault = "---";

  try {
    if (property?.id) {
      if (property?.nomeImovel) return `${property?.nomeImovel}`;

      let label = `${property?.tipo || ''}`;

      if (property?.dormitorio) {
        label = `${label}, ${
          parseInt(property?.dormitorio)
            ? property?.dormitorio + " Dormitórios"
            : ""
        }`;
      }

      if (property?.neighborhood?.data?.nome) {
        label = `${label}, ${property?.neighborhood?.data?.nome}`;
      }

      return label;
    }
  } catch (e) {
    console.log(e);
  }

  return labelDefault;
};

/**
 * Return price info of property list.
 */
export const getPriceInfoProperty = (property, noLabel) => {
  const labelDefault = `${!noLabel ? " - " : ""}R$ Entre em contato`;

  try {
    if (property?.sitePublicarValor)
      if (
        property?.valor &&
        property?.valor !== "0,00" &&
        property?.valor !== "0.00"
      )
        return `${!noLabel ? " - " : ""}R$ ${property?.valor}`;
  } catch (e) {
    console.log(e);
  }

  return labelDefault;
};

/**
 * Return main photo or photo default.
 */
export const getPhotoProperty = (property, size) => {
  const src_default = "/property-default.png";

  try {
    if (property?.photo?.data?.[size]) return property?.photo?.data?.[size];
  } catch (e) {
    console.log(e);
  }

  return src_default;
};

/**
 * Get location.
 */
export const getNeighborhoodProperty = (property) => {
  const labelDefault = "--";

  try {
    return `${property.neighborhood.data.nome} - ${property.neighborhood.data.city.data.name}`;
  } catch (e) {
    console.log(e);
  }

  return labelDefault;
};

/**
 * Get navigation title.
 */
export const getNavigationTitle = (page) => `Ir para página ${page}`;

/**
 * Check is web browser or device.
 */
export const isDeviceMobile = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);

  // Helper.consoleBeggin();
  // console.log("isDeviceMobile check:", check);
  // Helper.consoleEnd();

  return check;
};

/**
 * Get link to API web application or deeplink open App of social media.
 */
export const openLinkWebOrDeeplinkDevice = (socialMedia) => {
  const valueDefault = "";

  try {
    if (isDeviceMobile() && socialMedia?.link_device)
      return socialMedia.link_device;
    return socialMedia.link;
  } catch (e) {
    console.log(e);
  }

  return valueDefault;
};
