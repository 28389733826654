///////
// RJS
//////
import React, { useEffect, useState } from "react";

/**
 * Routes.
 */
import { Link } from "react-router-dom";

//////////////
// Application
//////////////

/**
 * Services.
 */
import { serviceGetFeaturedProperties } from "../../services/api/properties";

/**
 * Constants.
 */
import { dataBanks } from "../../constants";

/**
 * Styles.
 */
import "./styles.css";

/**
 * Components.
 */
import FinancingBox from "./components/FinancingBox";

const Financing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="PropertyDetailInfosContainer">
          <div className="PropertyDetailInfosContent">
            <h1 className="PropertyDetailInfosTitle">
              Financiamento
            </h1>
            <p className="PropertyDetailInfosDesc">Simule seu financiamento no banco de sua preferência</p>
          </div>
        </div>

      <div className="Financing">
        <div className="FinancingContent">

          {dataBanks?.length && dataBanks.map(bank => (
            <FinancingBox data={bank} />
          ))}

        </div>
      </div>
    </>
  );
};

export default Financing;
