///////
// RJS
//////
import React from "react";

/**
 * React router dom.
 */

/**
 * Link: Link prevent refresh page implemented a real redirect in applications SIGLE PAGE APPLICATION.
 */
import { Link } from "react-router-dom";

/**
 * React Icons.
 */
import { FaLocationArrow } from "react-icons/fa";

////////////////
// Application.
////////////////

/**
 * Component.
 */
import Exclusividade from "../../../components/exclusividade";

/**
 * Helpers.
 */
import * as HOH from "../../../helpers/highOrderHelper";

/**
 * Style.
 */
import "./styles.css";

const PropertyRow = ({ property }) => {
  return (
    <div className="HomeContentPropertyContainer">
      <div className="HomeContentProperty">
        <div className="HomeContentPropertyColLeft">
          <div className="HomeContentPropertyInfos">
            <h2 className="HomeContentPropertyTitle">
              {HOH.getTitleInfoProperty(property)}
              {/* <br /> */}
              {/* <small>{HOH.getNeighborhoodProperty(property)}</small> */}
            </h2>
            <p className="HomeContentPropertyLocation">
              <FaLocationArrow className="HomeContentPropertyLocationIcon" />{" "}
              {HOH.getNeighborhoodProperty(property)}
            </p>
            <div className="HomeContentPropertyDesc">{property.descGeral}</div>
            <div className="HomeContentPropertyActions">
              <Link
                to={HOH.getUrlProperty(property)}
                className="HomeContentPropertyButton"
                title={`Ver detalhes do imóvel ${property?.code}`}
              >
                Conhecer +
              </Link>
            </div>
          </div>
        </div>
        <div className="HomeContentPropertyColRight">
          <div className="HomeContentPropertyImageContainer">
            {property?.hasExclusividade && <Exclusividade />}
            <Link
              to={HOH.getUrlProperty(property)}
              title={`Ver detalhes do imóvel ${property?.code}`}
            >
              <img
                className="HomeContentPropertyImage"
                src={HOH.getPhotoProperty(property, "thumb")}
                alt={`Imóvel ${property?.code} em destaque - Schenckel NI`}
              />
            </Link>
            <span className="LabelPropertyValor">
              {HOH.getCodeInfoProperty(property)}
              {HOH.getPriceInfoProperty(property)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyRow;
