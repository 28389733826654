///////
// RJS
//////
import React from "react";

/**
 * React router dom.
 */

/**
 * Link: Link prevent refresh page implemented a real redirect in applications SIGLE PAGE APPLICATION.
 */
import { Link } from "react-router-dom";

/**
 * React Icons.
 */
import { FaLocationArrow } from "react-icons/fa";

////////////////
// Application.
////////////////

/**
 * Component.
 */
import Exclusividade from "../../../components/exclusividade";

/**
 * Helpers.
 */
import * as Helper from "../../../helpers";
import * as HOH from "../../../helpers/highOrderHelper";

/**
 * Style.
 */
import "./styles.css";

const PropertyBox = ({ property }) => {
  console.log("PropertyBox property:", property);

  return (
    <div className="HomeContentPropertiesBoxes">
      <div className="HomeContentPropertyBoxContainer">
        <div className="HomeContentPropertyBox">
          <div className="HomeContentPropertyHeader">
            {property?.hasExclusividade && <Exclusividade />}
            {/* <Link
              to={HOH.getUrlProperty(property)}
              title={`Ver detalhes do imóvel ${property?.code}`}
            > */}
            <Link
              to={HOH.getUrlProperty(property)}
              title={`Ver detalhes do imóvel ${property?.code}`}
              className="HomeContentPropertyBoxImageContainer"
              style={{
                backgroundImage: `url(${HOH.getPhotoProperty(
                  property,
                  "thumb"
                )})`,
              }}
            ></Link>
            {/* </Link> */}
            <span className="LabelPropertyValor">
              {HOH.getCodeInfoProperty(property)}
              {HOH.getPriceInfoProperty(property)}
            </span>
          </div>
          <div className="HomeContentPropertyBody">
            <h3 className="HomeContentPropertyBodyTitle">
              {HOH.getTitleInfoProperty(property)}
            </h3>
            <p className="HomeContentPropertyBodyLocation">
              <FaLocationArrow className="HomeContentPropertyBodyLocationIcon" />{" "}
              {HOH.getNeighborhoodProperty(property)}
            </p>
            <p className="HomeContentPropertyBodyDesc">{property?.descGeral}</p>
          </div>
          <div className="HomeContentPropertyFooter">
            <div className="HomeContentPropertyFooterAttributes">
              {!!Helper.isValidDecimal(property?.areaTotal) && (
                <div className="HomeContentPropertyFooterAttribute">
                  <span>{property?.areaTotal}</span>
                  <span>m²</span>
                </div>
              )}
              {!!Helper.isValidInt(property?.garagem) > 0 && (
                <div className="HomeContentPropertyFooterAttribute">
                  <span>{property?.garagem} carro(s)</span>
                </div>
              )}
              {!!Helper.isValidInt(property?.dormitorio) > 0 && (
                <div className="HomeContentPropertyFooterAttribute">
                  <span>{property?.dormitorio} dorm(s)</span>
                </div>
              )}
            </div>
            <div className="HomeContentPropertyFooterActions">
              <Link
                to={HOH.getUrlProperty(property)}
                className="HomeContentPropertyFooterAction"
                title={`Ver detalhes do imóvel ${property?.code}`}
              >
                Conhecer +
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyBox;
