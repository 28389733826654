import { useEffect } from 'react';

const useScript = innerHtml => {
  useEffect(() => {
    const script = document.createElement('script');

    if (innerHtml) script.innerHTML = innerHtml

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [innerHtml]);
};

export default useScript;